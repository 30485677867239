.durationChangeLine {
    position: relative;
    margin-top: 80px;
    height: 210px;
    text-align: center;
    font: normal normal 300 52px/150px "Bio Sans";
    color: #FFFFFF;
}

.durationStart {
    position: absolute;
    left: 30px;
    top: 30px;
    width: 151px;
    height: 151px;
    background: #050020 0% 0% no-repeat padding-box;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;
}

.durationEnd {
    position: absolute;
    right: 30px;
    top: 30px;
    width: 151px;
    height: 151px;
    background: #050020 0% 0% no-repeat padding-box;
    border-radius: 50%;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;
}

.line {
    margin-top: 95px;
    width: 5120px;
    height: 20px;
    background: transparent linear-gradient(90deg, #FFFFFF00 0%, #FFFFFF 15%, #FFFFFF 83%, #FFFFFF00 100%) 0% 0% no-repeat padding-box;
    mix-blend-mode: soft-light;
}

.leftDiv{
    height: 211px;
    width: 651px;
    position: absolute;
    /* background-color: orange; */
    top: 0px;
    right: calc(50% - 1px);
}

.rightDiv{
    height: 211px;
    width: 651px;
    position: absolute;
    /* background-color: red; */
    top: 0px;
    left: calc(50% - 1px);
}

.gradientDivLeft{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    background: transparent linear-gradient(90deg, #77EDDC 0%, #DBFD95 188%, #DEFE93 200%) 0% 0% no-repeat padding-box;
}

.gradientDivRight{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    background: transparent linear-gradient(90deg, #DEFE93  0%, #DBFD95 12%, #77EDDC 200%) 0% 0% no-repeat padding-box;
    transform: rotate(180deg);
}

.gradientRectangleLeft{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    background: transparent linear-gradient(90deg, #77EDDC 0%, #DBFD95 188%, #DEFE93 200%) 0% 0% no-repeat padding-box;
    clip-path: polygon(259px 75.5px, 101% 75.5px, 101% 135.5px, 259px 135.5px)
}

.gradientRectangleRight{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    background: transparent linear-gradient(90deg, #DEFE93  0%, #DBFD95 12%, #77EDDC 200%) 0% 0% no-repeat padding-box;
    clip-path: polygon(259px 75.5px, 102% 75.5px, 102% 135.5px, 259px 135.5px);
    transform: rotate(180deg);
}

.grabbingZoneLeft{
    width: 211px;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    border-radius: 50%;
}

.grabbingZoneRight{
    width: 211px;
    height: 100%;
    position: absolute;
    top: 0px;
    right: 0px;
    border-radius: 50%;
}

/* de aici e codul vechi*/

.referencedDiv{
    height: 211px;
    width: 651px;
    position: absolute;
    /* background-color: orange; */
    top: 0px;
    left: 50%;
}

.gradientDiv{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    background: transparent linear-gradient(90deg, #77EDDC 0%, #DBFD95 94%, #DEFE93 100%) 0% 0% no-repeat padding-box;
}

.gradientDreptDiv{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    background: transparent linear-gradient(270deg, #77EDDC 0%, #DBFD95 94%, #DEFE93 100%) 0% 0% no-repeat padding-box;
    transform: rotate(180deg);
}
