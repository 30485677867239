.line{
    width: calc(100% - 130px);
    position: relative;
    display: flex;
    flex-direction: row;
    padding-bottom: 38px;
    border-bottom: 1px solid #5E547C;
    padding-left: 65px;
    padding-right: 65px;
    overflow-x: scroll;
}

.line::-webkit-scrollbar{
    display: none;
}

.iconPen{
    margin-right: 20px;
}


.infoDescription{
    font: normal normal normal 40px/42px "Bio Sans";
    color: #CDCCD2;
    display: flex;
    flex-direction: row;
    padding-bottom: 5px;
}

.infoBox{
    display: flex;
    flex-direction: column;
    border-right: 1px solid #5E547C;
    position: relative;
    white-space: nowrap; 
}

.location{
    font: normal normal 600 48px/80px "Bio Sans";  
}

.hour {
    font: normal normal 600 48px/80px "Bio Sans";  
}

.localTime {
    font: italic normal normal 28px/42px "Bio Sans";
    color: #CDCCD2;
}

.participantCard{
    white-space: nowrap; 

    position: relative;
    display: flex;
    flex-direction: row;
    margin-top: 80px;
}

.participantDetails {
    position: relative;
    display: flex;
    flex-direction: column;
    font: normal normal bold 22px/27px "Bio Sans";
    color: #EEEEEE;
    margin-top: 30px;
    margin-left: 25px;
}

.participantName {
    font: normal normal 600 48px/54px "Bio Sans";
    color: #FFFFFF;
}

.iconSmall{
    margin-right: 20px;
    margin-top: 8px;
}
