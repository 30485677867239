.component {
    display: flex;
    position: relative;
    flex-direction: column;
    background: #060020;
    video {
        object-fit: fill;
        position: absolute;    
    }   
}

.list {
    width: 100%;
    height: calc(100% - 209px);
    display: flex;
    flex-direction: row;
    margin-bottom: 40px;
    font-size: 70px;
    justify-content: center;
    flex-shrink: 0;
    position: relative;
}

.overflowing_list {
    width: 5050px;
    height: calc(100% - 209px);
    /* height: 90%; */
    display: flex;
    flex-direction: row;
    /* margin-bottom: 40px; */
    font-size: 70px;
    overflow-x: scroll;
    padding-left: 80px;
    padding-right: 80px;
    z-index: 1;
    flex-shrink: 0;
    position: relative;

}

.overflowing_list::-webkit-scrollbar {
    display: none;
}

.checkbox {
    width: 40px;
    margin: 10px;
}

.bottomShadow {
    /* z-index: 15; */
    position: absolute;
    bottom: -1px;
    left: 0px;
    width: 100%;
    height: 264px;
    background: transparent linear-gradient(0deg, #060020 0%, #060020 43%, #06002000 100%) 0% 0% no-repeat padding-box;
}

.topShadow {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 240px;
    background: transparent linear-gradient(0deg, #06002000 0%, #060020 43%, #060020 100%) 0% 0% no-repeat padding-box;
}

.background{
    object-fit: fill;
    position: absolute;    
    pointer-events: none;
}

.rightShadow {
    position: absolute;
    z-index: 15;
    top: 198px;
    width:120px;
    height: 2000px;
    right:0px;
    background: transparent linear-gradient(-90deg, #060020 0%, #06002000 100%) 0% 0% no-repeat padding-box;
    
}

.leftShadow {
    position: absolute;
    z-index: 15;
    top: 198px;
    width:120px;
    height: 2000px;
    left:0px;
    background: transparent linear-gradient(90deg, #060020 0%, #06002000 100%) 0% 0% no-repeat padding-box;
}