.current_user {
    width: 100px;
    height: 100px;
    background: transparent linear-gradient(180deg, #EEEEEE 0%, #C1C1C1 100%) 0% 0% no-repeat padding-box;
    border-radius: 50%;
    text-align: center;
    font: normal normal normal 54px/90px "Bio Sans";
    letter-spacing: 0px;
    color: #0D0032;
    text-shadow: 0px 2px 8px #00000033;
    margin-top: 31px;
    margin-left: 80px;
    align-items: center;
    display: flex;
    justify-content: center;
    position: relative;
    z-index: 4;
    user-select: none;
    cursor: pointer;
}