.line_pres{
    width: calc(100% - 130px);
    position: relative;
    display: flex;
    flex-direction: row;

    padding-left: 65px;
    padding-right: 65px;
    overflow-x: scroll;
    margin-top: -100px;
}

.line_pres::-webkit-scrollbar{
    display: none;
}

.line{
    width: calc(100% - 130px);
    position: relative;
    display: flex;
    flex-direction: row;
    padding-bottom: 38px;
    border-bottom: 1px solid #5E547C;
    padding-left: 65px;
    padding-right: 65px;
    overflow-x: scroll;
}

.line::-webkit-scrollbar{
    display: none;
}

.iconPen{
    margin-top: 3px;
    margin-right: 20px;
}

.iconSmall{
    margin-right: 20px;
    margin-top: 8px;

}


.infoDescription{
    font: normal normal normal 36px/42px "Bio Sans";
    color: #CDCCD2;
    display: flex;
    flex-direction: row;
    padding-bottom: 5px;
}

.infoBox{
    display: flex;
    flex-direction: column;
    border-right: 1px solid #5E547C;
    position: relative;
    white-space: nowrap; 
}

.location{
    font: normal normal 600 40px/80px "Bio Sans";  
}

.hour {
    font: normal normal 600 48px/80px "Bio Sans";  
}

.localTime {
    font: italic normal normal 28px/42px "Bio Sans";
    color: #CDCCD2;
}

.scheduleTimeBox{
    position: relative;
    display: flex;
    flex-direction: row;
    margin-top: 20px 
}

.scheduleTime{
    margin-right: 50px;
    padding-right: 50px;
    
}
