.component{
    width: 100px;
    height: 60px;
    background: transparent;
    border: 1px solid transparent;
    border-radius: 16px;
    
}

.dots{
    color: white;
    text-align: center;
    margin-top: -30px;
    opacity: 1;
}

.componentClicked{
    width: 100px;
    height: 60px;
    background: #060020 0% 0% no-repeat padding-box;
    border: 1px solid #5E547C;
    border-radius: 16px;
    
}

.dotsClicked{
    color: #33FFC8;
    text-align: center;
    margin-top: -30px;
}