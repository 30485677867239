.simple-keyboard.hg-theme-default.myTheme {
    height: 100%;
    box-sizing: content-box;
    padding: 38px 30px 38px 30px;
    box-shadow: 0px 20px 80px #00000099;
    border-radius: 40px;
    background: transparent linear-gradient(180deg, #2E2748 0%, #221A3E 100%) 0% 0% no-repeat padding-box;
}

.simple-keyboard.hg-layout-default .hg-button.hg-conf {
    color: #FFFFFF;
    border: 0;
    background: #362A55CC 0% 0% no-repeat padding-box;
    border-radius: 22px;
    height: 117px;
    width: 117px;
    margin: 0px 6px 7px 6px;
    font: normal normal 300 32px/39px "Bio Sans";
}

.simple-keyboard.hg-layout-default .hg-button.hg-conf-first-line {
    color: #FFFFFF;
    border: 0;
    background: #362A55CC 0% 0% no-repeat padding-box;
    border-radius: 22px;
    height: 90px;
    margin: 6px;
    width: 117px;
    font: normal normal 300 32px/39px "Bio Sans";
}

.simple-keyboard.hg-layout-default .hg-button.hg-conf-com {
    width: 252px;
    height: 117px;
    align-items: end;
    justify-content: left;
    padding: 0px 0px 20px 25px;
    box-sizing: border-box;
    font: normal normal 300 28px/39px "Bio Sans";
}

.hg-theme-default .hg-button.hg-standardBtn[data-skbtn=".com"] {
    max-width: 252px;
}

.simple-keyboard.hg-layout-default .hg-button.hg-conf-shift {
    width: 407px;
    height: 117px;
    align-items: end;
    justify-content: left;
    padding: 0px 0px 20px 25px;
    box-sizing: border-box;
    font: normal normal 300 28px/39px "Bio Sans";
}

.simple-keyboard.hg-layout-default .hg-button.hg-conf-caps {
    width: 455px;
    height: 117px;
    align-items: end;
    justify-content: left;
    padding: 0px 0px 20px 25px;
    box-sizing: border-box;
    font: normal normal 300 28px/39px "Bio Sans";
}

.simple-keyboard.hg-layout-default .hg-button.hg-conf-tab {
    width: 390px;
    height: 117px;
    align-items: end;
    justify-content: left;
    padding: 0px 0px 20px 25px;
    box-sizing: border-box;
    font: normal normal 300 28px/39px "Bio Sans";
}

.simple-keyboard.hg-layout-default .hg-button.hg-conf-enter {
    width: 216px;
    height: 117px;
    align-items: end;
    justify-content: right;
    padding: 0px 25px 20px 0px;
    box-sizing: border-box;
    font: normal normal 300 28px/39px "Bio Sans";
}

.simple-keyboard.hg-layout-default .hg-button.hg-conf-bksp {
    width: 411px;
    height: 90px;
    align-items: end;
    justify-content: right;
    padding: 0px 25px 20px 0px;
    box-sizing: border-box;
    font: normal normal 300 28px/39px "Bio Sans";
}

.simple-keyboard.hg-layout-default .hg-button.hg-conf-click {
    box-shadow: inset 0px 0px 30px #33FFC8;
    border: 2px solid #33FFC8;
    box-sizing: border-box;
}

.hg-theme-default .hg-button.hg-standardBtn[data-skbtn="@"] {
    max-width: 117px;
}

