.component {
    position: relative;
    width: 100%;
    /* max-height: 2010px; */
    height: auto;
    display: flex;
    flex-direction: column;
}

.title {
    position: relative;
    display: flex;
    flex-direction: row;
    font: normal normal 600 40px/50px "Bio Sans";
    letter-spacing: 2px;
    color: #726FA8;
    padding-top: 28px;
    z-index: 4;
}

.titleGraphic {
    transform: rotate(-90deg); /* Fixed the matrix transform issue */
    margin-right: 70px;
}

.card {
    width: calc(100% - 8px);
    height: 100%;
    max-height: 2152px;
    background: #2D274880 0% 0% no-repeat padding-box;
    backdrop-filter: blur(31px);
    border-radius: 40px;
    display: flex;
    flex-direction: row;
    font: normal normal 600 64px/84px "Bio Sans";
    color: #FFFFFF;
    margin-bottom: 25px;
    padding: 70px 20px;
    box-sizing: border-box; /* Ensures padding doesn't affect size */
}

.scrollContainer {
    max-height: 100%;
    overflow: auto; /* Allow scrolling */
    scrollbar-width: none; /* Hide scrollbar for Firefox */
    -ms-overflow-style: none; /* Hide scrollbar for IE/Edge */
    top: 15px;
    position: relative;
}

.scrollContainer::-webkit-scrollbar {
    display: none; /* Hide scrollbar for Chrome/Safari */
}

