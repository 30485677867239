#MyTable tr:hover {
    td {
        background: #f6f9ff;
        overflow: hidden;
        #editButton {
            transition: all 0.5s;
            display: block;
            transform: translate(0px, 0px);
        }

        #deleteButton {
            transition: all 0.5s;
            display: block;
            transform: translate(0px, 0px);
        }
    }
}



#MyTable tr {
    td {
        overflow: hidden;
        #editButton {
            transition: all 0.5s;
            display: block;
            transform: translate(0px, -40px);
        }
        #deleteButton {
            transition: all 0.5s;
            display: block;
            transform: translate(0px, 40px);
        }
    }
}