.touch_component {
    position: absolute;
    will-change: transform;
    cursor: grab;
    touch-action: none;
    user-select: none;
    -webkit-user-select: none;

    overflow-y: none;
    
    pointer-events: auto;    
    
    background-color: transparent;      
    box-shadow: 0 0 30px 5px #33FFC8;   

}

.touch_component_shared {
    position: absolute;
    will-change: transform;
    cursor: grab;
    touch-action: none;
    user-select: none;
    -webkit-user-select: none;

    overflow-y: none;
    
    pointer-events: auto;
    width: auto; 
    height: auto;
    background-color: transparent;
}

.touch_component_moderator {
    position: absolute;
    will-change: transform;
    cursor: grab;
    touch-action: none;
    user-select: none;
    -webkit-user-select: none;

    overflow-y: none;
    
    pointer-events: auto;
    width: auto; 
    height: auto;
    background-color: transparent;
    box-shadow: 0 0 30px 5px #FF6200;
}