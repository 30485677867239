.select_screen {
    width: 100%;
    height: 70%;
    display: flex;
    flex-direction: row;
}

.select_lists {
    width: 60%;
    height: auto;
    display: flex;
    flex-direction: column;
}

.search {
    width: 47%;
    height: 85%;
    display: flex;
    flex-direction: column;
    padding: 20px;
    font-size: 30px;
}

.select_options {
    width: 100%;
    height: 30%;
    display: flex;
    flex-direction: row;
}

.localFiles {
    width: 619.54px
}

.addButton {
    position: absolute;
    bottom: 79.5px;
    left: 1283.5px;
    background: #33FFC8 no-repeat padding-box;
    border-radius: 16px;
    font: normal normal 600 40px/33px "Bio Sans";
    letter-spacing: 0;
    color: #02000A;
    opacity: 1;
}

.inputName {
    width: 100%;
    height: 120px;
    display: flex;
    flex-direction: row;
    padding: 20px;
    position: relative;
    font: normal normal 600 40px/54px "Bio Sans";
    background: #1D1536 0% 0% no-repeat padding-box;
    margin-top: 1.5px;
    align-items: center; 
}

.localFileName {
    font-size: 30px;
    padding: 20px;
    margin-left: 50px;
}

.input {
    width: 0px;
    height: 0px;
}

.localFilesLabel {
    position: absolute;
    top: 108.5px;
    left: 1300px;
    height: 71px;

    font: 600 58px/80px "Bio Sans";
    letter-spacing: 0;
    color: #FFFFFF;
    text-align: left;
    opacity: 1;
}

.dragDropLabel {
    position: absolute;
    top: 352.5px;
    left: 550.04px;
    width: 552px;
    height: 164px;
    font: normal normal 600 48px/36px Bio Sans;
    letter-spacing: 0;
    color: #423C5A;
    text-align: center;
    /* opacity: 0.2; */
    display: flex;
    flex-direction: column;
}

.dragDropZone {
    top: 371px;
    width: 1500px;
    left: 1300px;
    position: absolute;
    height: 880px;
    flex-direction: column;
    display: flex;
    padding-top: 46.5px;
}

.orLine {
    position: absolute;
    top: 371px;
    left: 1300px;
    width: 1540px;
    height: 0;
    border: 1px solid #FFFFFF;
    opacity: 0.2;
}

.verLine {
    position: absolute;
    top: 80px;
    left: 2907.5px;
    width: 0;
    height: 1320px;
    border: 1px solid #FFFFFF;
    opacity: 0.2;
}

.docsFilesLabel {
    position: absolute;
    top: 108.5px;
    left: 3035.5px;
    height: 71px;
    font: normal normal 600 58px/80px "Bio Sans";
    letter-spacing: 0;
    color: #FFFFFF;
    opacity: 1;
}

.buttonsDiv {
    top: 199.5px;
    left: 3028.5px;
    position: absolute;
    display: flex;
    flex-direction: row;
    width: 1600px;
}

.selectListDiv {
    top: 199.5px;
    left: 3955.99px;
    position: absolute;
    display: flex;
    flex-direction: row;
    width: 1600px;
}

.divCheckBox {
    position: absolute;
    top: 371px;
    left: 3028px;
    width: 1500px;
}

.tableHeader{
    height: 40px;
    padding: 20px 30px 20px 30px;
    border-bottom: 4px solid #484060;
    border-top: 4px solid #484060;
    font: normal normal 600 32px/30px "Bio Sans";
    color: #9B99A5;
    text-transform: uppercase;
    display: flex;
    flex-direction: row;
    width: 1500px;
    /* justify-content: space-evenly; */
    position: relative;

}


.centerElement{
    position: absolute;
    top: 25px;
    left: 70%;
    transform: translate(-50%, 0%);
}

.img {
    max-width: 100px;  
    max-height: 100px;
    /* border: 1px solid #ffffff; */
    width: auto;
    height: auto;
    position: relative;
    object-fit: contain; 
}

.imgDiv{
    display: flex;
    justify-content: center;
    width: 100px;
    height: 100px;
    align-content: center;
    margin-left: 5px;
    margin-right: 25px;
}

.icon {
    margin-right: 20px;
}

.iconPlus {
    margin-right: 20px;
    margin-top: 20px;
}

.iconSearch {
    position: absolute;
    font: normal normal 300 48px/58px "Bio Sans";
    color: #E4E4E4;
    margin-top: 55px;
    margin-left: 40px;
}

.iconTrash {
    right: 45px;
    position: absolute;
}

.iconEditElement {
    position: absolute;
    margin-left: 1340px;
}

.bigIcon{
    font-size: 236px;
    margin-bottom: 50px;
}