.component{
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    position: absolute;
    background: #060020E6 0% 0% no-repeat padding-box;
    /* opacity: 0.9;  */
    display: flex;
    flex-direction: column; 
}

.image{
    margin-top: 570px;
    width: 1200px;
    height: 720px;
    position: relative;
    left: 50%;
    transform: translate(-50%, 0);
}

.title{
    text-align: center;
    font: normal normal 600 107px/142px "Bio Sans";
}

.message{
    text-align: center;
    font: normal normal normal 36px/42px "Bio Sans";
}

.buttons{
    margin-top: 51px;
    justify-content: center;
    display: flex;
    flex-direction: row;
}