.title{
    margin-top: 50px;
    font: normal normal 600 58px/80px "Bio Sans";
    margin-bottom: 20px;
    position: relative;
}

.iconEdit{
    position: absolute;
    margin-top: 280px;
    left: 2770px;
    color: #938FA0;
    font-size: 40px;
}

.iconEdit2{
    position: absolute;
    margin-top: 527px;
    left: 2770px;
    color: #938FA0;
    font-size: 40px;
}



