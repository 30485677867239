.roomDisplay {
    position: absolute;
    top: 169px;
    left: calc(26% + 120px);
    width: calc(74% - 171px);
    height: calc(100% - 220px);
    background: #1D1536 0% 0% no-repeat padding-box;
    box-shadow: inset 0px 0px 99px #00000055;
    border: 4px solid #5E547CCC;
    border-radius: 28px;
}

.roomsTab {
    position: absolute;
    top: 35px;
    left: calc(26% + 148px);
    height: 134px;
    display: flex;
    flex-direction: row;
}



.roomCardSelected {
    position: relative;
    bottom: -10px;
    margin-left: 52px;
    width: auto;
    height: 120px;
    background: #1D1536 0% 0% no-repeat padding-box;
    border-radius: 40px 40px 0px 0px;
    color: #33FFC8;
    font: normal normal bold 40px/40px "Bio Sans";
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 20px;
}

.roomCard {
    position: relative;
    bottom: 0px;
    margin-left: 52px;
    width: auto;
    height: 114px;
    background: #1D1536 0% 0% no-repeat padding-box;
    border-radius: 40px 40px 0px 0px;
    color: #FFFFFF;
    opacity: 0.3;
    font: normal normal bold 40px/40px "Bio Sans";
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 20px;

}



.remoteImage {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.moveIcon {
    width: 150px;
    height: 150px;
    background: #060020B3 0% 0% no-repeat padding-box;
    border-radius: 20px;
    /* opacity: 0.7; */
    position: absolute;
    bottom: 18px;
    right: 18px;
    text-align: center;
    font: normal normal 600 20px/24px "Bio Sans";
    letter-spacing: 0px;
    color: #746C8D;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.deskNumber {
    position: absolute;
    width: 88px;
    height: 88px;
    background: #6D6884 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 40px #000000E5;
    border: 6px solid #1D1536;
    font: normal normal bold 60px/80px "Bio Sans";
    color: #1D1536;
    text-align: center;
    border-radius: 50%;
}