.title{
    font: normal normal 600 58px/30px 'Bio Sans';
}

.addedParticipantsList{
    position: relative;
    margin-top: 30px;
    width: 100%;
    max-height: calc(100% - 230px);
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    padding-top: 30px;
}

.addedParticipantsList::-webkit-scrollbar {
    display: none;
}

.positionCard{
    position: relative;
    height: 170px;
    width: 100%;
    background: #050020 0% 0% no-repeat padding-box;
    border-radius: 21px;
    margin-bottom: 21px;
    flex: 0 0 auto;
}


.numberedCircle {
    position: relative;
    margin-top: 20px;
    margin-left: 33px; 
    width: 121px;
    height: 121px;
    background: #6D6884 0% 0% no-repeat padding-box;
    border: 6px solid #060020;
    /* opacity: 0.45; */
    text-align: center;
    font: normal normal bold 72px/110px "Bio Sans";
    color: #1D1536;
    border-radius: 50%;
}

.assignedParticipant{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    display: flex;
    flex-direction: row;
    background: #00000080 0% 0% no-repeat padding-box;
    border-radius: 20px;
    opacity: 1;
}

.assignedDeskNumber{
    position: absolute;
    top: 0px;
    left: 0px;
    width: 62px;
    height: 62px;
    background: #33FFC8 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 40px #000000E5;
    border: 6px solid #1D1536;
    text-align: center;
    font: normal normal bold 33px/60px "Bio Sans";
    color: #1D1536;
    border-radius: 50%;
}

.participantDetails {
    position: relative;
    display: flex;
    flex-direction: column;
    font: normal normal bold 22px/27px "Bio Sans";
    color: #EEEEEE;
    margin-top: 30px;
    margin-left: 10px;
    padding-left: 15px;
}

.participantName {
    font: normal normal 600 48px/54px "Bio Sans";
    color: #FFFFFF;
    max-width: 600px;
    overflow: scroll;
    white-space: nowrap;
    position: relative;
    /* box-shadow: inset 10px 0px 10px #050020, inset -20px 0px 20px gold; */
}
.participantName::-webkit-scrollbar{
    display: none;
}

.participantNameShadow {
    position: absolute;
    top: -10px;
    left: 0px;
    width: 615px;
    height: 101%;
    box-shadow: inset 10px 0px 10px #050020, inset -20px 0px 20px #050020;
    pointer-events: none;
}



.participantListShadow{
    position: absolute;
    bottom: calc(10% - 2px);
    left: 2px;
    width: 100%;
    height: 161px;
    background: transparent linear-gradient(180deg, #2D274800 0%, #2D2748 100%) 0% 0% no-repeat padding-box;
    
}

.icon{
    color: #E4E4E4;
    position: absolute;
    top: 15px;
    right: 20px;
    font: normal normal 300 32px/38px "Font Awesome 6 Pro";
}

.participantListShadow{
    position: absolute;
    bottom: calc(11% + 6px);
    width: 100%;
    height: 161px;
    background: transparent linear-gradient(180deg, #2D274800 0%, #2D2748 100%) 0% 0% no-repeat padding-box;
}

.participantListShadowUp{
    position: absolute;
    top: calc(7% + 2px);
    width: calc(100% - 80px);
    height: 80px;
    background: transparent linear-gradient(180deg, #2D2748 0%, #2D274800 100%) 0% 0% no-repeat padding-box;
}