@import url("https://use.typekit.net/chq2xpl.css");

.element{
    margin-top: 20px;
    /* margin-left: 25px; */
    width: 1095px;
    height: auto;
    background: #231A3E80 0% 0% no-repeat padding-box;
    border-radius: 26px;
    backdrop-filter: blur(31px);
    font: normal normal 600 48px/54px "Bio Sans";
    display: flex;
    flex-direction: row;
    padding: 20px 50px 50px 0px;
    justify-content: space-between;

}

.info{
    display: flex;
    flex-direction: row;
}

.participantDetails{
    display: flex;
    flex-direction: column;
    padding: 30px 0px 0px 40px;
}

.participantDescription {
    display: flex;
    flex-direction: row;
    font: normal normal normal 28px/35px "Bio Sans";
    color: #CDCCD2;
}

.participantRole {
    font: normal normal bold 28px/35px "Bio Sans";
    color: #33FFC8;
    margin-left: 10px;
}

.iconButton {
    font-size: 34px;
    position: absolute;
    top: 0px;
    right: 0px;
}