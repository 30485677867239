

.column{
    height: 100%;
    position: relative;
    /* background-color: darkblue; */
    display: flex;
    flex-direction: column;
    /* overflow-y: scroll; */
    /* overflow-x: hidden; */
}

.column::-webkit-scrollbar{
    display: none;
}

.body{
    height: calc(100% - 210px);
    margin-left: 120px;
    margin-right: 120px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}