@import url("https://use.typekit.net/chq2xpl.css");

.project_element{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    width: 1190px;
    background: transparent linear-gradient(91deg, #150C2E  0%, #060020 100%) 0% 0% no-repeat padding-box;
    border: 1px solid #484060;
    border-radius: 40px;
    margin: 40px 35px 40px 35px;
    color: #CDCCD2;
    text-align: center;
    font-size: 40px;
    flex-shrink: 0;
    position: relative;
}

.creation_date_div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 70px;
    font: normal normal normal 40px/42px "Bio Sans";
}

.creation_date{
    color: white;
    margin-left: 10px;
}

.title_div{
    margin-top: 110px;
    width: 100%;
    height: 444px;
    color: #FFFFFF;
    font-size: 120px;
    justify-content: center;
    display: flex;
    flex-direction: column;
    position: relative;
}

.title_tags{
    width: 100%;
    height: 40px;
    justify-content: center;
    display: flex;
    flex-direction: row;
    font-size: 20px;
}
.title{
    font: normal normal 600 120px/128px "Bio Sans";
    width: 90%;
    left: 50%;
    position: relative;
    transform: translate(-50%,0);
    /* z-index: 1; */
}


.details_div{
    margin-top: 110px;
    width: 1190px;
    height: 873px;
    /* background: #231A3E 0% 0% no-repeat padding-box; */
    backdrop-filter: blur(31px);
    display: flex;
    flex-direction: column;
    margin-bottom: 100px;
}

.details{
    width: 950px;
    height: 210px;
    padding: 20px;
    margin-left: 100px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-align: left;
}


.join_conf{
    width: 469px;
    height: 57px;
    background: #33FFC8 0% 0% no-repeat padding-box;
    border-radius: 8px;
    margin-top: 20px;
    color: #02000A;
    padding-top: 14px;
    text-align: center;
}

.half_detail{
    width: 435px;
    /* height: auto; */
    padding-right: 20px;
    padding-left: 20px;
    justify-content: center;
    display: flex;
    flex-direction: column;
    /* border-right: 4px solid #69627D; */
    font: normal normal normal 40px/42px "Bio Sans";
}

.half_detail_title{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 290px;
}

.detail_accent{
    font-size: 92px;
    color: white;
    font: normal normal normal 92px/92px "Bio Sans";
}

.line {
    border: 2px solid #FFFFFF33;
}

.lottieBackground {
    position: absolute;
}


.buttonView{
    position: absolute;
    animation: smooth-appear 0.5s ease-out;
    bottom: 530px;
}

.buttonViewStatic{
    position: absolute;
    bottom: 530px;
}

@keyframes smooth-appear {
    0% {
        bottom: 50px;
    }
    100% {
        bottom: 530px;
    }
}

.buttonViewClose{
    position: absolute;
    animation: smooth-disappear 0.5s ease-out;
    bottom: 50px;
}

.buttonViewCloseStatic{
    position: absolute;
    bottom: 50px;
}

@keyframes smooth-disappear {
    0% {
        bottom: 530px;
    }
    100% {
        bottom: 50px;
    }
}

.bottomShadow {
    position: absolute;
    bottom: 460px;
    left: 0px;
    width: 100%;
    height: 264px;
    background: transparent linear-gradient(0deg, #060020 0%, #060020 43%, #06002000 100%) 0% 0% no-repeat padding-box;
    animation: smooth-appear-shadow 0.5s ease-out;
}

.bottomShadowStatic{
    position: absolute;
    bottom: 460px;
    left: 0px;
    width: 100%;
    height: 264px;
    background: transparent linear-gradient(0deg, #060020 0%, #060020 43%, #06002000 100%) 0% 0% no-repeat padding-box;
}

@keyframes smooth-appear-shadow {
    0% {
        bottom: -100px;
    }
    100% {
        bottom: 460px;
    }
}

.bottomShadowClose {
    position: absolute;
    bottom: -200px;
    left: 0px;
    width: 100%;
    height: 264px;
    background: transparent linear-gradient(0deg, #060020 0%, #060020 43%, #06002000 100%) 0% 0% no-repeat padding-box;
    animation: smooth-disappear-shadow 0.5s ease-out;
}

@keyframes smooth-disappear-shadow {
    0% {
        bottom: 460px;
    }
    100% {
        bottom: -200px;
    }
}