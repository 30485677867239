.openFiles{
    position: relative;
    top: 20px;
    left: 0px;
    margin-left: 67px;
    margin-right: 67px;
    /* height: auto; */
    height: 100%;
    display: flex;
    flex-direction: column;
    /* align-self: center; */
    /* overflow-x: scroll; */
}

.noFiles{
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: center; */
    /* border-bottom: 1px solid #5E547C; */
    overflow-y: scroll;
}

.noFiles::-webkit-scrollbar{
    display: none;
}

.description{
    font: normal normal normal 48px/52px "Bio Sans";
    color: #33FFC8;
}

.fileCard {
    display: flex;
    flex-direction: row;
    margin-left: 50px;
    margin-bottom: 50px;
    margin-top: 30px;
    position: relative;
}

.fileDetails{
    display: flex;
    flex-direction: column;
    padding-left: 35px;
    font: normal normal 600 48px/54px "Bio Sans";
    margin-top: -10px;
}

.fileDescription {
    display: flex;
    flex-direction: row;
    font: normal normal normal 28px/42px "Bio Sans";
    color: #CDCCD2;
    margin-top: 10px;
}

.fileAddedBy {
    font: normal normal bold 28px/42px "Bio Sans";
    color: #33FFC8;
    margin-left: 10px;
}

.icon {
    font-size: 64px;
}

.iconPlus {
    margin-right: 20px;
}

.iconButton {
    font-size: 48px;
    position: absolute;
    top: 0px;
    right: 0px;
}

.column::-webkit-scrollbar{
    display: none;
}

/* .fileCard:first-child {
    margin-top: 110px; /* Ensures the first file is visible */
