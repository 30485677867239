.title{
    margin-top: 308px;
    font: normal normal 600 58px/80px "Bio Sans";
    margin-bottom: 20px;
}

.iconEdit{
    position: absolute;
    margin-top: 444px;
    left: 2300px;
    color: #938FA0;
    font-size: 40px;
}

.explanation{
    font: normal normal normal 36px/42px "Bio Sans";
    color: #FFFFFF;
    opacity: 0.5;
    margin-top: 20px;
}

