.index {
    margin-top: -30px;
    margin-bottom: 10px;
    font: normal normal 600 58px/80px "Bio Sans";
    color: #FFFFFF;
}

.details {
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    font: normal normal 600 48px/54px "Bio Sans";
    color: #CDCCD2;
}

.notExtended {
    position: relative;
    margin-right: 71px;
    margin-left: 71px;
    display: flex;
    flex-direction: column;
}

.icon {
    font-size: 32px;
    margin-right: 20px;
    margin-top: 12px;
}

.iconPlus {
    font-size: 32px;
    margin-right: 20px;
    top: 25px;
}

.moderatorInfo {
    margin-left: 50px;
    display: flex;
    flex-direction: row;
}

.extended {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    border-top: 1px solid #5E547C;
    border-bottom: 1px solid #5E547C;
    padding-top: 20px;
    padding-bottom: 30px;
    font: normal normal 600 28px/42px "Bio Sans";
    color: #CDCCD2;
    margin-left: 71px;
    margin-right: 71px;
    position: relative;
    /* font-family: "Bio Sans";
    font-weight: 600;
    font-style: normal; */
}

.subtopic {
    font: normal normal normal 40px/54px "Bio Sans";
    color: #FFFFFF;
    margin-left: 40px;
    margin-top: 20px;
    margin-bottom: 20px;
    position: relative;
}

.moderator {
    padding-top: 20px;
    margin-top: 20px;
    border-top: 1px solid #5E547C;
}


.participantCard {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: row;
}

.participantDetails {
    position: relative;
    display: flex;
    flex-direction: column;
    font: normal normal bold 22px/27px "Bio Sans";
    color: #EEEEEE;
    margin-top: 30px;
    margin-left: 25px;
}

.participantName {
    font: normal normal 600 48px/54px "Bio Sans";
    color: #FFFFFF;
}

.addSubtopic {
    font: normal normal 600 28px/54px "Bio Sans";
    color: #FFFFFF;
    position: absolute;
    top: 10px;
    right: 5px;
}

.iconOpenFiles {
    position: absolute;
    top: 70px;
    right: 0px;
}

.iconButton {
    font-size: 48px;
    position: absolute;
    top: 80px;
    right: 0px;
}

.iconButtonSubtopics {
    font-size: 28px;
    position: absolute;
    top: 10px;
    right: 0px;
}

.iconOpenFilesSubtopic {
    position: absolute;
    top: 10px;
    right: 0px;
    font-size: 28px;
}

.centered {
    width: 1056px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.labelTextBold {
    font: normal 36px/42px 'bio-sans';
    letter-spacing: 0;
    color: #FFFFFF80;
    text-align: center;
    margin-top: 18px;
}

.labelCreate {
    font: normal 600 68px/80px 'bio-sans';
    letter-spacing: 0;
    color: #FFFFFF;
    text-align: center;
    margin-top: -84px;
}

.imgAdd {
    width: 800px;
    height: 520px;
}

.shadowUp{
    position: absolute;
    top: -15px;
    left: 0px;
    width: 108.5%;
    height: 230px;
    background: transparent linear-gradient(180deg, #060020CC 0%, #060020 80%, #06002000 100%) 0% 0% no-repeat padding-box;
}