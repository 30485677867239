.leftBody {
    display: flex;
    flex-direction: column;
    width: 1080px;
    border-right: 1px solid #484060;
    justify-content: center;
    padding-right: 80px;
}

.image {
    width: 800px;
    height: 520px;
    /* margin-top: 117px;
    margin-left: 100px; */
    align-self: center;
}

.subtitle {
    width: 500px;
    text-align: center;
    font: normal normal normal 48px/52px "Bio Sans";
    color: #FFFFFF;
    align-self: center
}

.imgTitle {
    width: 100%;
    text-align: center;
    font: normal normal 600 60px/71px "Bio Sans";
    color: #FFFFFF;
    /* margin: -50px 0px 0px 250px; */
    align-self: center
}

.imgDescription {
    margin: 18px 00px 0px 0px;
    text-align: center;
    font: normal normal normal 36px/42px "Bio Sans";
    letter-spacing: 0px;
    color: #FFFFFF;
    align-self: center;
    opacity: 0.5;
}

.imgConfDescription {
    margin-top: 18px;
    text-align: center;
    font: normal normal normal 36px/42px "Bio Sans";
    letter-spacing: 0px;
    color: #FFFFFF;
    align-self: center;
    opacity: 0.5;
}