@import url("https://use.typekit.net/chq2xpl.css");


.tag{
    margin-left: 10px;
    margin-right: 10px;
    width: auto;
    border-radius: 20px;
    padding-top: 10px;
    padding-bottom: 8px;
    padding-left: 16px;
    padding-right: 16px;
    font: normal normal bold 20px/110px "Bio Sans";
}

.tagText {
    position: relative;
    top: -45px;
}