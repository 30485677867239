.background {
    top: -20px;
    left: 0px;
    position: absolute;
    width: 5210px;
    height: 2200px;
}

.icon {
    width: 33px;
    height: 45px;
    text-align: center;
    font: normal normal normal 44px/54px "Bio Sans";
    margin-right: 15px;
}




.title {
    position: relative;
    margin-top: 80px;
    text-align: center;
    font: normal normal normal 34px/39px "Bio Sans";
    color: #CDCCD2;
}

.titleInput {
    font: normal normal 600 160px/180px "Bio Sans";
    color: #FFFFFF;
}

.leftShadow {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 600px;
    height: 2160px;
    background: transparent linear-gradient(90deg, #060020 0%, #060020 40%, #06002000 100%) 0% 0% no-repeat padding-box;
}

.rightShadow {
    position: absolute;
    top: 0px;
    right: 0px;
    width: 600px;
    height: 2160px;
    background: transparent linear-gradient(-90deg, #060020 0%, #060020 40%, #06002000 100%) 0% 0% no-repeat padding-box;
}


.buttonDiv {
    bottom: 50px;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    display: flex;
    flex-direction: row;
}

