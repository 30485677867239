.goBack {
    margin-left: 40px;
    margin-right: 20px;
    margin-top: 56px;
    font-size: 48px;
    position: relative;

}

.goBackText {
    font: normal normal 600 40px/155px "Bio Sans";
    position: relative;
}


/* .titleCenterText{
    text-align: center;
    position: absolute;
    text-align: center;
    font: normal normal 600 68px/145px "Bio Sans";
    color: #FFFFFF;
    left: 50%;
    transform: translate(-50%, 0);
} */

.titleCenterText{
    text-align: center;
    text-align: center;
    font: normal normal 600 68px/120px "Bio Sans";
    color: #FFFFFF;
    /* left: 50%; */
    /* transform: translate(-50%, 0); */
}

.editName {
    display: flex;
    flex-direction: row;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    margin-top: 15px;
}

.editIconClicked {
    font-size: 48px;
    margin-left: 40px;
    margin-top: 35px;
    position: relative;
    /* position: absolute; */
    /* left: 1290px; */
}
