.subtopics{
    display: flex;
    flex-direction: column;
    margin-top: 73px;
    font: normal normal 600 58px/80px "Bio Sans";
}

.subtopicsAdd{
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    margin-top: 4px;
    margin-bottom: 13px;
    position: relative;
}


.subtopics_map{
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    width: 1560px;
    height: 440px;
    background: #1D1536 0% 0% no-repeat padding-box;
    border: 1px solid #707070;
    border-radius: 28px;
    padding: 20px;
    font: normal normal 600 40px/52px "Bio Sans";
    color: #FFFFFF;
    margin-top: 30px;
    position: relative;
}

.subtopics_map::-webkit-scrollbar {
    display: none;
}

.subtopicsHeader{
    display: flex;
    flex-direction: row;
}

.subtopicsOptional{
    font: normal normal normal 36px/80px "Bio Sans";
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 0.5;
    margin-left: 19px;
    margin-top: 5px;
}



.body_header{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font: normal normal 600 58px/80px "Bio Sans";
    color: #FFFFFF;
    margin-top: 57px;
}



.icon {
    margin-right: 20px;
    margin-top: 20px;
}

.footerButtons{
    flex-direction: row;
    display: flex;
}

.iconPen{
    position: relative;
    top: 20px;
    right: 100px;
    color: #938FA0;
    font-size: 40px;
}

.iconEdit{
    position: absolute;
    margin-top: 5px;
    right: 130px;
    color: #938FA0;
    font-size: 40px;
}

.iconPenTopic{
    position: absolute;
    top: 250px;
    right: 105.7px;
    color: #938FA0;
    font-size: 40px;
}

.iconEditElement {
    position: absolute;
    margin-top: 20px;
    right: 20px;
    color: #938FA0;
    font-size: 40px;
}