.hours {
    position: relative;
    display: flex;
    font: normal normal 600 49px/69px "Bio Sans";
    margin-top: 80px;
    overflow-x: scroll;
    scroll-snap-type: x mandatory;
    scrollbar-width: none;
    width: 100%;
    overflow-y: visible;
    height: 129px;
    /* margin-left: 80px; */
}

.hours::-webkit-scrollbar {
    display: none;
}

.time {
    position: relative;
    padding: 20px 25px 20px 15px;
    margin-top: 20px;
    width: 153px;
    display: flex;
    margin-left: 10px;
    scroll-snap-align: start;
    /* border-right: 1px solid #FFFFFF4D; */
    border-right: 1px solid wheat;
    overflow-y: visible;
}

.minutes {
    font: normal normal 300 49px/69px "Bio Sans";
}

.selectedHours {
    position: absolute;
    display: flex;
    font: normal normal 600 49px/69px "Bio Sans";
    top: 233px;
    /* left: 80px; */
    width: 5060px;
    height: 100px;
    color: #050020;
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none;
    pointer-events: none;
}


.selectedHour {
    top: 264px;
    left: 50%;
    width: 151px;
    height: 110px;
    background: transparent linear-gradient(90deg, #77EDDC 0%, #9FFEBA 52%, #DEFE93 100%) 0% 0% no-repeat padding-box;
    position: absolute;
}


.dayConferenceHour {
    top: 31.5px;
    left: 50%;
    width: 182px;
    height: 103px;
    background: transparent linear-gradient(90deg, #5E547C 0%, #5E547C66 100%) 0% 0% no-repeat padding-box;
    position: absolute;
}

.nextDay{
    font: normal normal normal 28px/42px "Bio Sans";
    color: #33FFC8;
    height: 42px;
    position: absolute;
    top:-20px;
}

.roomName{
    left: 50px;
    height: 110px;
    position: absolute;
    font: normal normal bold 40px/42px "Bio Sans";
}