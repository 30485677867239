.custom-switch .form-check-input:checked {
    background-color: #1D1536;
    border-color: #33FFC8;
    border-width: 2px;
    
}
.custom-switch .form-check-input {
    background-color: white;
}

.custom-switch .form-check-input:checked:focus {
    box-shadow: 0 0 0 0.2rem #33FFC8;
}

.custom-switch {
    margin-left: 20px;
    margin-top: -5px;
}

.custom-switch .form-check-label{
    margin-top: 5px;
}