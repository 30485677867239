.touch_component {
    position: absolute;
    will-change: transform;
    cursor: grab;
    touch-action: none;
    user-select: none;
    -webkit-user-select: none;

    overflow-y: none;    
    pointer-events: auto;    
    
    background-color: transparent;      
    box-shadow: 0 0 30px 5px #33FFC8;   
}

.touch_component_shared {
    position: absolute;
    will-change: transform;
    cursor: grab;
    touch-action: none;
    user-select: none;
    -webkit-user-select: none;

    overflow-y: none;
    
    pointer-events: auto;
    width: auto; 
    height: auto;
    background-color: transparent;
}

.touch_component_moderator {
    position: absolute;
    will-change: transform;
    cursor: grab;
    touch-action: none;
    user-select: none;
    -webkit-user-select: none;

    overflow-y: none;
    
    pointer-events: auto;
    width: auto; 
    height: auto;
    background-color: transparent;
    box-shadow: 0 0 30px 5px #FF6200;
}
/* .touch_component {
    width: 100px; 
    height: 100px; 

    transition: width 0.5s ease, height 0.5s ease;

    position: absolute;
    will-change: transform;
    cursor: grab;
    touch-action: none;
    user-select: none;
    -webkit-user-select: none;

    overflow: hidden;
    overflow-y: none;
    
    
    pointer-events: auto;
    width: auto; 
    height: auto;
    background-color: transparent;   
    
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    background-color: rgba(0, 0, 0, 0.3);
    
} */