@import url("https://use.typekit.net/chq2xpl.css");

.input {
    height: 95px;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
    /* font-size: 60px; */
    border: 2px solid grey;
    border-radius: 10px;
    border-color: transparent;
}


.search_input {
    width: 705px;
    height: 76px;
    background: #3F3857 0% 0% no-repeat padding-box;
    border-radius: 50px;
    border-color: transparent;
    position: absolute;
    top: 42px;
    left: 300px;
    /* transform: translate(-50%, -50%); */
    font-size: 40px;
    color: #FFFFFF;
    padding-left: 116px;
    padding-right: 80px;
    font: normal normal normal 40px/42px "Bio Sans";
}

.search_input:focus {
    background: #150C2E 0% 0% no-repeat padding-box;
    border: 4px solid #33FFC8;
    outline: 0;
    top: 39px;
    left: 296px;
}

input::placeholder {
    color: #CDCCD2; 
}

.searchPopup {
    margin-top: 35px;
    width: 684px;
    height: 82px;
    background: #3F3857 0% 0% no-repeat padding-box;
    border-radius: 50px;
    font: normal normal normal 40px/42px "Bio Sans";
    color: #CDCCD2;
    padding-left: 117px;
    border: none;
}

.searchPopup:focus {
    margin-top: 35px;
    width: 676px;
    height: 74px;
    background: #150C2E 0% 0% no-repeat padding-box;
    border: 4px solid #34FFC8;
    outline: 0;
}

.editName {
    width: 35%;
    height: 124px;
    background: #060020 0% 0% no-repeat padding-box;
    border: 2px solid #34FFC8;
    border-radius: 31px;
    width: 1426px;
    font: normal normal 600 68px/80px "Bio Sans";
    color: #FFFFFF;
    text-align: center;
    position: relative;
    padding-left: 50px;
    padding-right: 50px;
    margin-left: -50px;
    /* background: url(../../graphics/pen.svg) no-repeat right; */
}

.editName:focus {
    border: 2px solid #33FFC8;
    outline: 0;
}

.inputAddBox {
    position: absolute;
    top: 68px;
    width: 1600px;
    height: 80px;
    color: #FFFFFF;
    background-color: #261E41;
    background-repeat: no-repeat;
    background-attachment: padding-box;
    border: 2px solid #5E547C;
    border-radius: 20px;
    opacity: 1;
    font: normal normal 600 40px/52px 'Bio Sans';
    letter-spacing: 0px;

    letter-spacing: 0px;

}

.inputPopup{
    height: 80px;
    background: #261E41 0% 0% no-repeat padding-box;
    border-radius: 20px;
    font: normal normal 600 40px/42px "Bio Sans";
    color: #FFFFFF;
    padding-left: 40px;
    border-color: transparent;
    position: relative;
    /* border: 2px solid #33FFC8; */
}

.inputPopup:disabled {
    opacity:0.3;
  }

.inputPopup:focus {
    border: 2px solid #33FFC8;
    outline: 0;
}

.conference {
    margin-top: 10px;
    background: transparent;
    font: normal normal 600 160px/170px "Bio Sans";
    color: #FFFFFF;
    /* border-color: transparent; */
    border: none;
    text-align: center;
}

.conference:focus {
    border: none;
    color: #050020;
    background-color: white;
}


.inputPicker:focus {
    border: 4px solid #34FFC8;
    color: #FFFFFF;
    outline: 0;

}

.inputPopup::placeholder {
    color: #938FA0;
}

.files{
    height: 80px;
    background: #1D1536 0% 0% no-repeat padding-box;
    border-radius: 20px;
    font: normal normal 600 40px/42px "Bio Sans";
    color: #FFFFFF;
    padding-left: 40px;
    padding-right: 80px;
    border-color: transparent;
    position: relative;
    /* border: 2px solid #33FFC8; */
}

.files:disabled {
    opacity:0.3;
  }

.files:focus {
    border: 2px solid #33FFC8;
    background: #261E41 0% 0% no-repeat padding-box;
    outline: 0;
}