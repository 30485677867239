.component{
    width: 100%;
    height: 100%;
    display: flex;
    position: absolute;
    top: 0px;
    left: 0px;
    background-color: #060020CC;
    z-index: 10;
    overflow: hidden;
    backdrop-filter: blur(10px);
    

    video {
        object-fit: fill;
        position: absolute;    
    }
}