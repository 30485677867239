.title{
    margin-top: 50px;
    font: normal normal 600 58px/80px "Bio Sans";
    margin-bottom: 20px;
    position: relative;
}

.iconEdit{
    position: absolute;
    margin-top: 280px;
    left: 1970px;
    color: #938FA0;
    font-size: 40px;
}


.subTitle{
    margin-top: 40px;
    font: normal normal normal 32px/52px "Bio Sans";
    /* margin-bottom: 20px; */
    position: relative;
}

.username {
    font: normal normal normal 32px/52px "Bio Sans";
    color: #FFFFFF;
    opacity: 0.2;
}



