@import url("https://use.typekit.net/chq2xpl.css");

.component {
    width: 556px;
    height: 82px;
    background: #150C2E 0% 0% no-repeat padding-box;
    border: 2px solid #33FFC8;
    border-radius: 8px;
    font: normal normal 600 40px/77px "Bio Sans";
    color: #33FFC8;
    text-align: center;
}