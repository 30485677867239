.component{
    background-color: #060020;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
}

.rightBody{
    position: absolute;
    top: 36px;
    right: 37px;
    width: 59.5%;
    height: 90%;
    background: #2D2748 0% 0% no-repeat padding-box;
    box-shadow: 0px 30px 40px #00000099;
    border-radius: 40px;
    font: normal normal 600 58px/80px "Bio Sans";
}

.icon{
    margin-right: 20px;
}

.nrParticipants {
    position: relative;
    margin-top: 35px;
    margin-left: 51px;
    color: white;
}
