.project_component{
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background: #060020 0% 0% no-repeat padding-box;
    display: flex;
    flex-direction: column;
    color: white;
    overflow: hidden;
    video {
        object-fit: fill;
        position: absolute;    
    } 
}

.lists {
    width: auto;
    height: calc(100% - 205px);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-left: 120px;
    margin-right: 120px;
}



.guest_warning{
    font-size: 40px;
    position: relative;
    top: 100px;
    width: 100%;
    text-align: center;
}

.topShadow{
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 250px;
    background: transparent linear-gradient(180deg, #060020CC 0%, #060020CC 67%, #06002000 100%) 0% 0% no-repeat padding-box;
    z-index: 2;
}

.bottomShadow {
    position: absolute;
    bottom: -1px;
    left: 0px;
    width: 100%;
    height: 162px;
    background: transparent linear-gradient(0deg, #060020 0%, #06002000 100%) 0% 0% no-repeat padding-box;
    pointer-events: none;
}