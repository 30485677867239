@import url("https://use.typekit.net/chq2xpl.css");

.component {
    position: relative;
    margin-left: 20px;
    cursor: pointer;
}

.currentChoice{
    height: 71px;
    background: #060020 0% 0% no-repeat padding-box;
    border: 1px solid #484060;
    border-radius: 16px;
    color: #68667A;
    padding-left: 27px;
    font: normal normal 600 32px/70px "Bio Sans";
    justify-content: space-between;
    display: flex;
    align-items: center;
    flex-direction: row;
    box-sizing: border-box;
    letter-spacing: 0px;
}

.currentChoiceOpen {
    height: 71px;
    background: #060020 0% 0% no-repeat padding-box;
    border: 4px solid #34FFC8;
    border-radius: 16px;
    font: normal normal normal 32px/70px "Bio Sans";
    letter-spacing: 0.05px;
    color: #34FFC8;
    padding-left: 25px;
    justify-content: space-between;
    align-items: center;
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
}

.openBody {
    position: absolute;
    width: 850px;
    background: #2E2645 0% 0% no-repeat padding-box;
    box-shadow: 0px 30px 99px #000000;
    border: 1px solid #5E547C;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    padding-top: 15px;
    padding-bottom: 15px;
    margin-top: 20px;
    z-index: 12;
    max-height: 300px;
    overflow-y: scroll;
}

.option{
    margin-top: 5px;
    margin-bottom: 5px;
    /* width: 650px; */
    height: 80px;
    background: #5E547C 0% 0% no-repeat padding-box;
    display: flex;
    flex-direction: row;
    font: normal normal 600 32px/80px "Bio Sans";
    color: #FFFFFF;
    padding-left: 30px;
}

.optionSelected {
    margin-top: 5px;
    margin-bottom: 5px;
    height: 80px;
    background: #33FFC8 0% 0% no-repeat padding-box;;
    display: flex;
    flex-direction: row;
    font: normal normal 600 32px/80px "Bio Sans";
    color: #06001E;
    padding-left: 30px;
}

.icon {
    margin: 24px 20px 24px 0px;
}

.dropdownIcon {
    margin: 0px 20px 0px 0px;
}