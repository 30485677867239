.leftBody{
    position: absolute;
    top: 36px;
    left: 37px;
    width: 2003px;
    height: 90%;
    background: #2D2748 0% 0% no-repeat padding-box;
    box-shadow: 0px 30px 40px #00000099;
    border-radius: 40px;
}
.info {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 47%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-right: 2px solid #FFFFFF33;
    text-align: center;
}

.img {
    width: 100%;
    height: auto;
    position: relative;
}

.title{
    font: normal normal 600 74px/84px "Bio Sans";
    color: #FFFFFF;
    position: relative;
    margin-bottom: 10px;
}

.description{
    font: normal normal normal 36px/42px "Bio Sans";
    color: #FFFFFF80;
    position: relative;
    margin: 10px 10% 0px 10%;
}

.participantsFilterSearch{
    position: absolute;
    top: 0px;
    left: 47%;
    width: 1056px;
    height: 290px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-bottom: 2px solid #FFFFFF33;
}

.filter{
    position: relative;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.tag{
    height: 61px;
    border-radius: 50px;
    background-color: #FFFFFF1A;
    color: white;
    position: relative;
    text-align: center;
    font: normal normal 600 36px/36px "Bio Sans";
    padding: 16px 40px 0px 40px;
}

.selectedTag{
    height: 61px;
    border-radius: 50px;
    background-color: white;
    color: #060020;
    position: relative;
    text-align: center;
    font: normal normal 600 36px/36px "Bio Sans";
    padding: 16px 40px 0px 40px;
}

.iconMagnifyingGlass{
    position: absolute;
    font: normal normal 300 48px/58px "Bio Sans";
    color: #E4E4E4;
    margin-top: 110px;
    margin-left: 60px;
}

.participantsListAndAdd{
    position: absolute;
    top: 290px;
    left: 47%;
    width: 1056px;
    height: calc(100% - 290px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.participantsList{
    position: relative;
    width: 100%;
    height: 90%;
    display: flex;
    flex-direction: column;
    margin-top: 5px;
    overflow-y: scroll;
}

.participantsList::-webkit-scrollbar {
    display: none;
}

.participantCard{
    width: 805px;
    position: relative;
    display: flex;
    flex-direction: row;
    user-select: none;
    border-radius: 21px;
    background-color: #2D2748;
    margin-left: 5px;
}

.participantDetails {
    position: relative;
    display: flex;
    flex-direction: column;
    font: normal normal normal 22px/27px "Bio Sans";
    color: #EEEEEE;
    margin-top: 30px;
    margin-left: 25px;
}

.participantName {
    font: normal normal 600 48px/54px "Bio Sans";
    color: #FFFFFF;
}

.participantListShadow{
    position: absolute;
    bottom: calc(10% - 7px);
    left: 2px;
    width: 100%;
    height: 161px;
    background: transparent linear-gradient(180deg, #2D274800 0%, #2D2748 100%) 0% 0% no-repeat padding-box;
    
}

.icon{
    margin-right: 20px;
}