.moderatorBox{
    position: absolute;
    top: 2%;
    left: 50%;
    transform: translate(-50%, 0);
    width: 821px;
    height: 1316px;
    background: #2D2748 0% 0% no-repeat padding-box;
    box-shadow: 0px 20px 60px #000000CC;
    border: 2px solid #5E547C;
    border-radius: 40px;
    font: normal normal 600 40px/52px "Bio Sans";
    color: #FFFFFF;
    padding: 44px 50px 44px 50px;
    display: flex;
    flex-direction: column;
}


.participantsFilterSearch{
    position: relative;
    margin-top: 50px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.filter{
    position: relative;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.tag{
    height: 61px;
    border-radius: 50px;
    background-color: #6D6884;
    color: white;
    position: relative;
    text-align: center;
    font: normal normal 600 36px/36px "Bio Sans";
    padding: 16px 40px 0px 40px;
}

.selectedTag{
    height: 61px;
    border-radius: 50px;
    background-color: white;
    color: #060020;
    position: relative;
    text-align: center;
    font: normal normal 600 36px/36px "Bio Sans";
    padding: 16px 40px 0px 40px;
}

.iconMagnifyingGlass{
    position: absolute;
    font: normal normal 300 48px/58px "Bio Sans";
    color: #E4E4E4;
    margin-top: 110px;
    margin-left: 35px;
}


.participantsListAndAdd{
    position: relative;
    height: 87%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.participantsList{
    position: relative;
    width: 100%;
    height: 90%;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
}

.participantsList::-webkit-scrollbar {
    display: none;
}

.participantCard{
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: row;
}

.participantDetails {
    position: relative;
    display: flex;
    flex-direction: column;
    font: normal normal bold 22px/27px "Bio Sans";
    color: #EEEEEE;
    margin-top: 30px;
    margin-left: 25px;
}

.participantName {
    font: normal normal 600 48px/54px "Bio Sans";
    color: #FFFFFF;
}

.participantListShadow{
    position: absolute;
    bottom: 100px;
    width: 100%;
    height: 161px;
    pointer-events: none;
    background: transparent linear-gradient(180deg, #2D274800 0%, #2D2748 100%) 0% 0% no-repeat padding-box;
    
}