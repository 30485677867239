.header_large{
    font-size: 60px;
    text-align: center;
    width: 100%;
    position: absolute;
    top:8%;
}

.header{
    font-size: 60px;
    text-align: center;
    width: 100%;
    position: absolute;
    top:20%;
}