@import url("https://use.typekit.net/chq2xpl.css");

/* :root {

	--overflowY: scroll;
	scroll-behavior: smooth;
	overflow-y: var(--overflowY);
} */

iframe#webpack-dev-server-client-overlay{display:none!important}

.app_body {
    touch-action: none;
    position: absolute;
    color: #3E3E3E;
    font-family: "bio-sans", sans-serif;
    overflow: hidden;
    /* background: #060020; */
    
}

.app_body *{
    box-sizing: content-box;
    /* background: #060020; */
}

.app_body::after,
.app_body::before {
    box-sizing: content-box;
    /* background: #060020; */
}