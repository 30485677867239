.optionsWindow{
    position: relative;
    width: 850px;
    background: #2E2645 0% 0% no-repeat padding-box;
    box-shadow: 0px 30px 99px #000000;
    border: 1px solid #5E547C;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    
    margin-top: 20px;
    z-index: 12;
    overflow-y: auto;
    overflow-x: hidden;
}

.scrollContent {
    position: relative;
    width: 850px;
    display: flex;
    flex-direction: column;
    z-index: 12;
}

.option{
    margin-top: 5px;
    /* width: 650px; */
    height: 80px;
    background: #5E547C 0% 0% no-repeat padding-box;
    display: flex;
    flex-direction: row;
    font: normal normal 600 32px/80px "Bio Sans";
    color: #FFFFFF;
    padding-left: 30px;
}

.option:hover{
    margin-top: 5px;
    /* width: 650px; */
    height: 80px;
    background: #6b88be 0% 0% no-repeat padding-box;
    display: flex;
    flex-direction: row;
    font: normal normal 600 32px/80px "Bio Sans";
    color: #FFFFFF;
    padding-left: 30px;
}

.optionSelected {
    margin-top: 5px;
    height: 80px;
    background: #33FFC8 0% 0% no-repeat padding-box;;
    display: flex;
    flex-direction: row;
    font: normal normal 600 32px/80px "Bio Sans";
    color: #06001E;
    padding-left: 30px;
}

