@import url("https://use.typekit.net/chq2xpl.css");

.header{
    
    height: 160px;
    background: transparent linear-gradient(180deg, #2E2748 0%, #221A3E 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 20px #060020BC;
    border: 1px solid #484060;
    border-radius: 20px;
    margin-top: 49px;
    margin-left: 120px;
    margin-right: 120px;
    display: flex;
    flex-direction: row;
    position: relative;
    z-index: 3;
    width: auto;
    flex-shrink: 0;
}

.line{
    width: 100%;
    height: 2px;
    position: absolute;
    background-color: red;
    top: 50%;
    transform: translate(0,-50%);
    left: 0px;
}

.notifications {
    width: 90px;
    height: 90px;
    /* background-color: white; */
    margin-top: 35px;
    margin-left: 20px;
    text-align: center;
    font-size: 48px;
    color: #EEEEEE;
    position: relative;
}



.select_dropdown{
    left: 682px;
    width: 0px;
    height: 0px;
    background: #060020 0% 0% no-repeat padding-box;
    border: 1px solid #484060;
    border-radius: 16px;
    margin-top: 45px;
    margin-left: 40px;
    color: #68667A;
    font-size: 32px;
    padding-left: 27px;
    font: normal normal 600 32px/36px "Bio Sans";
}

.projects_number {
    width: 270px;
    height: 50px;
    text-align: left;
    letter-spacing: 0px;
    color: #FFFFFF;
    font-size: 40px;
    margin-top: 55px;
    margin-left: 20px;
    font: normal normal 600 40px/42px "Bio Sans";
}

.status_button_selected{
    width: auto;
    height: 66px;
    background: #EEEEEE 0% 0% no-repeat padding-box;
    border-radius: 50px;
    color: #060020;
    font-size: 40px;
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 16px;
    margin-top: 39px;
    margin-left: 20px;
}

.status_button{
    width: auto;
    height: 66px;
    background:  #3F3857 0% 0% no-repeat padding-box;
    border-radius: 50px;
    color: #FFFFFF;
    font-size: 40px;
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 16px;
    margin-top: 39px;
    margin-left: 20px;
}

.add_project{
    width: 568px;
    height: 62px;
    background: #33FFC8 0% 0% no-repeat padding-box;
    border-radius: 16px;
    margin-top: 39px;
    /* margin-left: 1920px; */
    color: #02000A;
    font-size: 40px;
    padding-top: 20px;
    text-align: center;
    font: normal normal 600 40px/33px "Bio Sans";
    display: flex;
    flex-direction: row;
    justify-content: center;
    position: absolute;
    left: 3716px;
}
.iconPlus{
    margin-right: 20px;
}
.date{
    color: #EEEEEE;
    margin-top: 59px;
    margin-left: 120px;
    font: normal normal 600 40px/42px "Bio Sans";
    position: absolute;
    right: 290px;

}

.time{
    color: #EEEEEE;
    margin-top: 59px;
    margin-left: 80px;
    font: normal normal 600 40px/42px "Bio Sans";
    position: absolute;
    right: 140px;
}

.select_dropdown_options{
    font-size: 32px;
    color: white;
    width: 573px;

}

.icon{
    position: absolute;
    font: normal normal 300 48px/58px "Bio Sans";
    color: #E4E4E4;
    margin-top: 58px;
    margin-left: 520px;
}

.backgroundImg {
    width: 100%;
    position: absolute;
}

.goBack {
    /* width: 90px;
    height: 90px; */
    margin-left: 40px;
    margin-right: 20px;
    margin-top: 56px;
    font-size: 48px;
    position: relative;

}

.goBackText {
    font: normal normal 600 40px/155px "Bio Sans";
    position: relative;
}

.notifications {
    width: 90px;
    height: 90px;
    /* background-color: white; */
    margin-top: 35px;
    margin-left: 20px;
    text-align: center;
    font-size: 48px;
    color: #EEEEEE;
    font: normal normal 300 48px/90px "Bio Sans";
    position: relative;

}