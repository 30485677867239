@import url("https://use.typekit.net/chq2xpl.css");


.component {
    background-color: #060020;
    display: flex;
    position: relative;
    flex-direction: column;
    text-align: center;
    font: normal normal 600 160px/128px "Bio Sans";
    color: #FFFFFF;
    /* justify-content: space-between; */

    video {
        object-fit: fill;
        position: absolute;
    }
}

.inputFields {
    display: flex;
    position: relative;
    flex-direction: column;
    text-align: center;
    height: 66%;
    justify-content: space-around;
}


.header {
    width: 100%;
    height: 20%;
    position: relative;
    text-align: center;
    font-size: 150px;
    position: relative;
}

.loginChoices {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    width: 1903px;
    height: auto;
    padding: 80px 0px;
    background: transparent linear-gradient(90deg, #150C2E 0%, #060020 100%) 0% 0% no-repeat padding-box;
    border-radius: 40px;
    left: 50%;
    transform: translate(-50%, 0);
    /* padding-left: 210px;
    padding-top: 75px; */
}

.keyboard {
    width: 100%;
    height: 40%;
    position: relative;
}

.guestLogin {
    /* padding-left: 1500px;
    padding-right: 1500px;
    
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column; */
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.languageButton {
    width: 104px;
    height: 78px;
    /* border: 4px solid #FFFFFF; */
    border-radius: 20px;
    text-align: center;
    margin: 0px 30px 0px 30px;
    box-sizing: border-box;
}

.languageChoices {
    position: relative;
    margin-top: 104px;
    left: 50%;
    transform: translate(-50%, 0%);
    display: flex;
    flex-direction: row;
    font-size: 20px;
    width: 328px;
}

.headerText {
    position: relative;
    margin-top: 41px;
    font-size: 120px;
}

.buttonLogin {
    height: 150px;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 80px;
    background-color: rgb(188, 185, 185);
    border: 2px solid rgb(188, 185, 185);
    border-radius: 10px;
    color: #575757;
}



.passError {
    font-size: 40px;
    color: red;
}



.headerDetails {
    text-align: center;
    font: normal normal normal 40px/42px "Bio Sans";
    color: #CDCCD2;
    position: relative;
    /* margin-top: 10px; */
}

.loginPos {
    margin-left: 40px;
}

.visitorDiv {

    width: 100%;
    height: 50px;
    position: relative;
    
    padding-bottom: 30px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.lineVisitor {
    background-color: #ffffff;
    height: 1px;
    width: 100%;
    
    opacity: 0.2;
}

.textChoice {
    font: normal normal normal 40px/42px 'Bio Sans';
    color: #CDCCD2;
    white-space: nowrap;
    margin: 0px 50px;
}

.closeX {
    font: normal 30px / 194px "Font Awesome 6 Pro";
    color: #938FA0;
    position: absolute;
    right: 240px;
    top: 170px;
}