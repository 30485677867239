@import url("https://use.typekit.net/chq2xpl.css");

.component{
    font-size: 40px;
    /* background: transparent linear-gradient(91deg, #150C2E 0%, #060020 100%) 0% 0% no-repeat padding-box; */
    /* border: 1px solid #5E547C; */
    border-radius: 40px;
    width: 1168px;
    height: calc(100% + 233px);
    /* margin-left: 22.5px;
    margin-right: 22.5px; */
    margin-top: -233px;
    position: relative;
}

.list{
    width: 100%;
    height: calc(100% - 510px);
    display: flex;
    flex-direction: column;
    margin-top: 220px;
    font-size: 30px;
    overflow-y: auto;
    position: relative;
    padding-top: 40px;
    
}
.list::-webkit-scrollbar {
    display: none;
}

.listNoAdmin{
    width: 100%;
    height: calc(100% - 510px);
    display: flex;
    flex-direction: column;
    margin-top: 200px;
    font-size: 30px;
    overflow-y: auto;
    position: relative;
    padding-top: 40px;
    
}
.listNoAdmin::-webkit-scrollbar {
    display: none;
}

.shadowUp{
    position: absolute;
    top: 245px;
    left: 0px;
    width: 108.5%;
    height: 320px;
    background: transparent linear-gradient(180deg, #060020CC 0%, #060020 69%, #06002000 100%) 0% 0% no-repeat padding-box;
    pointer-events: none;
}

.shadowDown{
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 100%;
    height: 162px;
    background: transparent linear-gradient(0deg, #060020 0%, #06002000 100%) 0% 0% no-repeat padding-box;
}

.title{
    position: relative;
    top: 233px;
    display: flex;
    flex-direction: row;
    font: normal normal 600 40px/50px "Bio Sans";
    letter-spacing: 2px;
    color: #726FA8;
    padding-top: 50px;
    z-index: 4;
}

.backgroundImage {
    position: absolute;
    top: 210px
}

.borderImg{
    width: 1145px;
    border-radius: 26px;
    backdrop-filter: blur(31px);
    -webkit-backdrop-filter: blur(31px);
    /* margin-left: 10px; */
    height: 1600px;
    background: #231A3E33 0% 0% no-repeat padding-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 295px;
}

.borderNoAdmin{
    width: 1145px;
    border-radius: 26px;
    backdrop-filter: blur(31px);
    -webkit-backdrop-filter: blur(31px);
    /* margin-left: 10px; */
    height: 2000px;
    background: #231A3E33 0% 0% no-repeat padding-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 260px;
}

.noListImage{
    width: 800px;
    height: 520px;
    /* margin-left: 170px; */
}

.noListImageSmall {
    width: 550px;
    height: 358px;
    /* margin-left: 255px; */
    margin-top: 34.5px;
}

.imgTitle{
    text-align: center;
    font: normal normal 600 60px/71px "Bio Sans";
    color: #FFFFFF;
    margin-top: -80px;
    max-width: 1000px;
    overflow: scroll;
}

.imgTitle::-webkit-scrollbar{
    display: none;
}


.imgDescription {
    margin: 32px 0px 60px 0px;
    text-align: center;
    font: normal normal normal 36px/42px "Bio Sans";
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 0.5;
    width: 900px;
    /* margin-left: 120px; */
}

.button {
    position: relative;
    top: 270px;
    z-index: 5;

}

.divConfMeet {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 260px;
    /* justify-content: space-between; */
}