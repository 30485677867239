
.notification{
    width: 100%;
    height: 500px;
    /* margin-top: -477px; */
    display: flex;
    flex-direction: column;
    position: relative;
    animation: smooth-appear 0.5s ease-out;
}

@keyframes smooth-appear {
    0% {
        margin-top: -477px;
        opacity: 0;
    }
    100% {
        margin-top: 30px;
        opacity: 1;
    }
}

.notification_close{
    width: 100%;
    height: 500px;
    /* margin-top: -477px; */
    display: flex;
    flex-direction: column;
    position: relative;
    animation: smooth-disappear 0.5s ease-out;
}

@keyframes smooth-disappear {
    0% {
        margin-top: 30px;
        opacity: 1;
    }
    70% {
        opacity: 0;
    }
    100% {
        margin-top: -477px;
        opacity: 0;
    }
}

.conferences_line{
    width: 100%;
    height: 230px;
    display: flex;
    flex-direction: row;
    position: relative;
    font-size: 40px;
    background: #160B3966 0% 0% no-repeat padding-box;
    /* box-shadow: 0px 40px 40px #00000095; */
    backdrop-filter: blur(30px);
}

.historyLine{
    width: 100%;
    height: 207px;
    display: flex;
    flex-direction: row;
    position: relative;
    font-size: 40px;
    background: #160B3966 0% 0% no-repeat padding-box;
    /* box-shadow: -30px 0px 40px #00000095; */
    /* opacity: 1; */
    backdrop-filter: blur(30px);
}

.line_title{
    margin-left: 143px;
    width: 311px;
    text-align: right;
    color: #33FFC8;
    margin-top: 60px;
    font: normal normal bold 40px/50px "Bio Sans";
}

.conference_rectangle {
    width: 700px;
    height: 200px;
    color: #2D2647;
    border-radius: 16px; 
    margin-left: 50px;
    margin-top: 10px;
    display: flex;
    flex-direction: row;
}

.upcoming_date{
    width: 215px;
    /* border-right: 1px solid #484060; */
    font: normal normal normal 32px/34px 'Bio Sans';
    text-align: center;
    align-self: center;

}

.upcoming_name{
    /* padding-top: 40px; */
    padding-left: 30px;
    width: 400px;
    font: normal normal normal 32px/36px 'Bio Sans';
    align-self: center;
}

.historyVerticalLine{
    margin-top: 25px;
}

.historyCircle{
    margin-top: 198px;
    margin-left: -600px;
    width: 21px;
    height: 30px;
}

.hourConf {
    font: normal normal bold 52px/52px 'Bio Sans';
}

.conf_type{
    width: 163px;
    height: 40px;
    background: #16a72c 0% 0% no-repeat padding-box;
    border-radius: 20px;
    position: relative;
    color: white;
    font: normal normal bold 20px/40px "Bio Sans";
    letter-spacing: 0.8px;
    margin-left: 27px;
    margin-top: 5px;
}