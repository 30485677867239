.title{
    margin-top: 50px;
    font: normal normal 600 58px/80px "Bio Sans";
    margin-bottom: 10px;
    position: relative;
}

.iconEdit{
    position: absolute;
    margin-top: 130px;
    left: 760px;
    color: #938FA0;
    font-size: 40px;
}

.explanation{
    font: normal normal normal 36px/42px "Bio Sans";
    color: #CDCCD2;
    margin-top: 20px;
    margin-left: 20px;
}


.subTitle{
    margin-top: 40px;
    font: normal normal normal 32px/52px "Bio Sans";
    /* margin-bottom: 20px; */
    position: relative;
}

.column{
    position: relative;
    width: 50%;
    display: flex;
    flex-direction: column;
    padding-left: 20px;
}

.bodyDetails{
    position: relative;
    width: 1648px;
    display: flex;
    flex-direction: row;
}


