@import url("https://use.typekit.net/chq2xpl.css");

:root {
	overflow: hidden;
}

body {
    overflow: hidden;
    margin: 0;
    font-family: "bio-sans", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}