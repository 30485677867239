@import url("https://use.typekit.net/chq2xpl.css");

.details_count_div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-left: 20px;
    margin-right: 60px;
    margin-top: 10px;
    font: normal normal normal 40px/42px "Bio Sans";
}

.details_count{
    color: white;
    margin-left: 10px;
    font: normal normal bold 40px/42px "Bio Sans";
}

.details_type{
    margin-left: 10px;
    font: normal normal normal 40px/42px "Bio Sans";
}

.icon{
    margin-top: 3px;
    margin-right: 10px;
    font-size: 35px;
    text-align: center;
}