.component{
    display: flex;
    flex-direction: column;
    position: relative;
    flex-grow: 1;
    justify-content: center;
}

.divNrParticipants {
    margin-top: 44px;
    position: relative;
    display: flex;
    flex-direction: column;
}

.nrParticipants {
    text-align: center;
    font: normal normal bold 80px/65px "Bio Sans";
    color: #FFFFFF;
}

.lblParticipants {
    margin-top: 5px;
    text-align: center;
    font: normal normal normal 30px/34px"Bio Sans";
    color: #CDCDCD;
    margin-bottom: 20px;

}

.participantsToAdd {
    height: 317px;
    position: relative;
    width: 100%;
    margin-top: 37px;
    display: flex;
    justify-content: center;
    position: relative;
}


.participantsList {
    bottom: 34.5px;
    position: absolute;
    height: 130px;
    display: flex;
    overflow-x: scroll;
    scroll-snap-type: x mandatory;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none;
    overflow-y: visible;
    /* background-color: red; */
}

.participantsList::-webkit-scrollbar {
    display: none;
}

.participantCard {
    width: 626px;
    height: 121px;
    text-align: center;
    flex-shrink: 0;
    scroll-snap-align: start;
    align-self: center;
    margin-left: 40px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.participantCircle {
    width: 120px;
    height: 100%;
    border-radius: 50%;
    background: transparent linear-gradient(180deg, #EEEEEE 0%, #C1C1C1 100%) 0% 0% no-repeat padding-box;
    border: 6px solid #0D0032;
    display: flex;
    align-items: center;
    justify-content: center;

}

.partcipantInitials {
    text-align: center;
    font: normal normal normal 54px/66px "Bio Sans";
    color: #0D0032;
    text-shadow: 0px 2px 8px #00000033;
}

.infoParticipants {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 472px;
    height: 120px;
}

.typeName {
    text-align: left;
    font: normal normal bold 22px/27px "Bio Sans";
    letter-spacing: 2.2px;
    color: #EEEEEE;
    text-transform: uppercase;
    opacity: 1;
}

.nameLabel {
    text-align: left;
    font: normal normal 600 48px/54px "Bio Sans";
    color: #FFFFFF;
}

.fundepDiv {
    width: 470px;
    height: 34px;
    display: flex;
    flex-direction: row;
}

.funcLabel {
    text-align: left;
    font: normal normal 600 28px/42px "Bio Sans";
    color: #EEEEEE;
}

.depLabel {
    text-align: left;
    font: normal normal normal 28px/42px "Bio Sans";
    color: #EEEEEE;
}

.divGroups {
    height: 81px;
    left: 50%;
    transform: translate(-50%, 0);
    position: absolute;
    display: flex;
    flex-direction: row;
}

.lineFolders {
    width: 100%;
    border: 2px solid #5E547C;
    position: absolute;
    top: 40px;
}

.groupClicked {
    height: 81px;
    background: #33FFC8 0% 0% no-repeat padding-box;
    border: 2px solid #2FFFC8;
    border-radius: 55px;
    display: flex;
    flex-direction: row;
    margin: 0px 20px 0px 20px;
}

.groupSimple {
    height: 81px;
    background: #060020 0% 0% no-repeat padding-box;
    border: 2px solid #5F988F;
    border-radius: 55px;
    display: flex;
    flex-direction: row;
    margin: 0px 20px 0px 20px;
}

.textClicked {
    text-align: left;
    font: normal normal normal 32px/45px "Bio Sans";
    letter-spacing: 0px;
    color: #0D0D12;
    margin-top: 19px;
    margin-bottom: 22px;
    margin-right: 40px;
    margin-left: 20px;
    position: relative;
}

.textSimple {
    text-align: left;
    font: normal normal normal 32px/45px "Bio Sans";
    letter-spacing: 0px;
    color: #FFFFFF;
    margin-top: 19px;
    margin-bottom: 22px;
    margin-right: 40px;
    margin-left: 20px;
    position: relative;
}

.circleClicked {
    width: 60px;
    height: 61px;
    background: #0D0D12 0% 0% no-repeat padding-box;
    border-radius: 50%;
    position: relative;
    margin-top: 10px;
    margin-left: 9px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.circle {
    width: 60px;
    height: 61px;
    background: #6B9A86 0% 0% no-repeat padding-box;
    ;
    border-radius: 50%;
    position: relative;
    margin-top: 10px;
    margin-left: 9px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.textCircle {
    text-align: center;
    font: normal normal bold 28px/45px "Bio Sans";
    color: #FFFFFF;
}

.backgroundParticipantsToSave {
    margin-top: 13px;
    height: 240px;
    border-radius: 131px;
    position: relative;
    display: flex;
    flex-direction: row;
    width: auto;
    justify-content: center;
    align-items: center;
}

.addedParticipants {
    margin-top: 13px;
    height: 180px;
    background: #302B49 0% 0% no-repeat padding-box;
    border-radius: 131px;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 30px;
    overflow: visible;
}

.addParticipantButton {
    margin-top: 13px;
    left: 50%;
    transform: translate(-50%, 0);
    width: 260px;
    height: 260px;
    background: #302B49 0% 0% no-repeat padding-box;
    border-radius: 131px;
    position: relative;
}