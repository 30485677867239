

.body{
    font-size: 60px;
    /* padding: 50px; */
    /* width: 1400px;
    height: 900px; */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* background-color: white;
    border: 3px solid grey; */
    color: #FFFFFF;
    width: 2924px;
    height: 1060px;
    background: #2D2748 0% 0% no-repeat padding-box;
    box-shadow: 0px 30px 40px #00000099;
    border-radius: 40px;
}

.body_large{
    font-size: 60px;
    padding: 80px;
    position: absolute;
    top: 196px;
    left: 50%;
    transform: translate(-50%, 0);
    display: flex;
    flex-direction: row;
    color: #FFFFFF;
    width: 2764px;
    height: 900px;
    background: #2D2748 0% 0% no-repeat padding-box;
    box-shadow: 0px 30px 40px #00000099;
    border-radius: 40px;
}