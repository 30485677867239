.custom-switch .form-check-input:checked {
    background-color: #1D1536;
    border-color: #33FFC8;
    border-width: 2px;
}
.custom-switch .form-check-input {
    /* background-color: white; */
    background-color: #1D1536;
}

.custom-switch .form-check-input:checked:focus {
    box-shadow: 0 0 0 0.2rem #33FFC8;
}

.custom-switch {
    margin-left: 20px;
    margin-top: -5px;
}

.custom-switch .form-check-label{
    margin-top: 5px;
}


.custom-checkbox input[type="checkbox"] {
    width: 40px;
    height: 40px;
    cursor: pointer;
    appearance: none;
    border: 2px solid #CDCCD2;
    border-radius: 4px;
    outline: none;
    transition: background-color 0.3s, border-color 0.3s;
    background-color: #2D2748;
    margin-top: 0px;
}
  
.custom-checkbox input[type="checkbox"]:checked {
    background-color: #CDCCD2; 
    /* background-color: #1D1536; */
    border-color: #CDCCD2; 
    --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 20 20%27%3e%3cpath fill=%27none%27 stroke=%27%231D1536%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%273%27 d=%27m6 10 3 3 6-6%27/%3e%3c/svg%3e");
}
  
  
.custom-checkbox label {
    margin-left: 20px;
    cursor: pointer;
    max-width: 850px;
    overflow: hidden;
    white-space: nowrap;
}

.custom-checkbox-mic input[type="checkbox"] {
    width: 40px;
    height: 40px;
    cursor: pointer;
    appearance: none;
    border: 2px solid #CDCCD2;
    border-radius: 4px;
    outline: none;
    transition: background-color 0.3s, border-color 0.3s;
    background-color: #2D2748;
    margin-top: -7px;
}
  
.custom-checkbox-mic input[type="checkbox"]:checked {
    background-color: #CDCCD2; 
    /* background-color: #1D1536; */
    border-color: #CDCCD2; 
    --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 20 20%27%3e%3cpath fill=%27none%27 stroke=%27%231D1536%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%273%27 d=%27m6 10 3 3 6-6%27/%3e%3c/svg%3e");
}
  
  
.custom-checkbox-mic label {
    margin-left: 27px;
    cursor: pointer;
}