.element{
    margin-top: 20px;
    width: 1045px;
    height: auto;
    /* background: #231A3E 0% 0% no-repeat padding-box; */
    background: #231A3E80 0% 0% no-repeat padding-box;

    border-radius: 26px;
    backdrop-filter: blur(31px);
    padding: 50px;
    /* margin-left: 50px; */
    font: normal normal 600 48px/54px "Bio Sans";
    /* display: flex;
    flex-direction: row;
    justify-content: space-between; */

}

.info{
    display: flex;
    flex-direction: row;
}

.participantDetails{
    display: flex;
    flex-direction: column;
    padding-left: 35px;
}

.participantDescription {
    display: flex;
    flex-direction: row;
    font: normal normal normal 28px/42px "Bio Sans";
    color: #CDCCD2;
}

.participantRole {
    font: normal normal bold 28px/42px "Bio Sans";
    color: #33FFC8;
    margin-left: 10px;
}

.icon {
    font-size: 64px;
}

.image {
    position: relative;
    margin-top: 10px;
    margin-left: 80px;
    max-height: 300px;
}

.fileName {
    max-width: 850px;
    overflow: scroll;
}

.fileName::-webkit-scrollbar{
    display: none;
}

.iconButton {
    font-size: 34px;
    position: absolute;
    top: 0px;
    right: 0px;
}