.icon {
    margin-right: 20px;
}

.iconPlus {
    margin-right: 20px;
    margin-top: 20px;
}

.footerButtons{
    flex-direction: row;
    display: flex;
    bottom: 80px;
    position: absolute;
}