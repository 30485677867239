.title{
    margin-top: 120px;
    font: normal normal 600 58px/80px "Bio Sans";
    margin-bottom: 20px;
}

.iconEdit{
    position: absolute;
    margin-top: 340px;
    left: 1970px;
    color: #938FA0;
    font-size: 40px;
}

.subTitle{
    margin-top: 30px;
    font: normal normal normal 32px/52px "Bio Sans";
    /* margin-bottom: 20px; */
    position: relative;
}

