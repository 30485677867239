.selectedCalendarDay {
    left: 50%;
    transform: translate(-50%, 0);
    width: 156px;
    height: 512px;
    border-radius: 14px;
    position: absolute;
}

.calendar {
    position: relative;
    width: 100%;
    display: flex;
    overflow-x: scroll;
    scroll-snap-type: x mandatory;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none;
    margin-top: 50px;
    padding-bottom: 50px;
}

.calendar::-webkit-scrollbar {
    display: none;
}

.calendarDay {
    width: 148.8px;
    text-align: center;
    flex-shrink: 0;
    scroll-snap-align: start;
}

.calendarMonth {
    font: normal normal normal 28px/42px "Bio Sans";
    color: #33FFC8;
    height: 42px;
}

.calendarDayNumber {
    font: normal normal bold 52px/69px "Bio Sans";
}

.calendarDayOfWeek {
    font: normal normal normal 32px/42px "Bio Sans";
}