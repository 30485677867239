.component {    
    background-color: #060020;
    display: flex;
    position: relative;
    flex-direction: column;
    text-align: center;
    font: normal normal 600 160px/128px "Bio Sans";
    color: #FFFFFF;

}

.component::-webkit-scrollbar {
    display: none;
}