.component {
    position: relative;
    top: 50px;
    /* top: 177px;
    left: 1965px; */
    width: 1190px;
    height: 1840px;
    background: #2E264733 0% 0% no-repeat padding-box;
    display: flex;
    flex-direction: column;
    border-radius: 40px;
    backdrop-filter: blur(31px);
}

.welcomeText {
    position: relative;
    font: 50px/78px "Bio Sans", sans-serif;
    color: #33FFC8;
    text-align: center;
}

.nameText {
    position: relative;
    font: 600 120px/170px "Bio Sans", sans-serif;
    color: #FFFFFF;
    text-align: center;
}

.confModeratorTextDiv {
    position: relative;
    width: 291px;
    height: 40px;
    background: #33FFC833;
    border-radius: 20px;
    margin-top: 159px;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 1;
    z-index: 100;
}

.confModeratorText {
    position: relative;
    font: bold 20px/22px "Bio Sans", sans-serif;
    letter-spacing: 0.8px;
    background-color: transparent;
    color: #33FFC8;
    text-align: left;
    text-transform: uppercase;
    opacity: 1;
}

.codeText {
    position: relative;
    font: 600 40px/46px "Bio Sans", sans-serif;
    color: #FFFFFF;
    margin-top: 20px;
    text-align: center;
}

.digitsDiv {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: relative;
    margin-top: 20px;
    width: 541px;
    height: 160px;
    opacity: 1;
}

.inputDigitFocused {
    width: 116px;
    height: 160px;
    background: #060020;
    border: 4px solid #33FFC8;
    border-radius: 20px;
    display: flex;
    align-content: center;
    flex-wrap: wrap;
    justify-content: center;
    opacity: 0.4;
}

.inputDigitFocused.active {
    opacity: 1;
}

.inputDigit {
    width: 38px;
    height: 94px;
    font: normal 77px/77px 'bio Sans';
    color: #33FFC8;
    text-align: center;
    background-color: transparent;
    border: none;
    border-bottom: 4px solid #33FFC8;
}

.inputDigit:focus {
    outline: none;
}

.customElement {
    margin-top: -90px;
    width: 161px;
    height: 161px;
    border-radius: 50%;
    background: #0B0425 0% 0% no-repeat padding-box;
}

.customText {
    width: 1066px;
    height: 98px;
    font: 600 80px/128px 'bio-sans', sans-serif;
    letter-spacing: 0;
    color: #FFFFFF;
    text-align: center;
    opacity: 1;
}

.customTextVisitor {
    width: 1066px;
    height: 59px;
    font: 600 48px/68px 'bio-sans', sans-serif;
    letter-spacing: 0;
    color: #FFFFFF;
    text-align: center;
    opacity: 1;
}

.customTextSmall {
    height: 50px;
    font: 600 40px/46px 'bio-sans', sans-serif;
    color: #FFFFFF;
    text-align: center;
    opacity: 0.5;
    margin-top: 20px;
}

.nameAndEntitleText {
    height: 71px;
    font: 600 48px/80px 'bio-sans', sans-serif;
    color: #FFFFFF;
    margin-bottom: 30px;
    margin-top: 15px;
    text-align: center;
}

.customPartnerText {
    width: 154px;
    height: 24px;
    font: bold 20px/22px 'bio-sans', sans-serif;
    color: #33FFC8;
    text-align: left;
}

.completedValue {
    position: relative;
    width: 116px;
    height: 160px;
    background-color: #33FFC8;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 1;
}

.completedLabel {
    width: 58px;
    height: 77px;
    font: bold 118px/78px 'bio-sans', sans-serif;
    color: #060020;
    text-align: center;
    opacity: 1;
    opacity: 1;
    position: absolute;
}


.digitsKeyboardElement {
    position: absolute;
    top: 1258px;
    left: 957px;
    width: 481px;
    height: 646px;
    background: linear-gradient(180deg, #2E2748 0%, #221A3E 100%);
    box-shadow: 0px 20px 80px rgba(0, 0, 0, 0.6);
    border: 1px solid #0D0D12;
    border-radius: 20px;
    padding: 36px;
    opacity: 1;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
    gap: 25px;
}

.digitkeyboard {
    width: 143px;
    height: 142px;
    background: #362A55CC 0% 0% no-repeat padding-box;
    border-radius: 22px;
    opacity: 1;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}


.digitTextKeyboard {
    width: 46px;
    height: 94px;
    font: normal normal normal 77px/77px 'bio-sans';
    letter-spacing: 0;
    color: #FFFFFF;
    text-align: center;
    opacity: 1;
}

.startButton {
    width: 650px;
    height: 82px;
    background: linear-gradient(90deg, #77EDDC 0%, #DBFD95 97%, #DEFE93 100%);
    border-radius: 8px;
    margin-top: 60px;
    font: normal normal 600 40px/33px 'bio-sans';
    letter-spacing: 0;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #02000A;
    text-transform: uppercase;
    text-align: center;
}

.joinButton {
    margin-top: 32px;
    width: 297px;
    height: 50px;
    font: 600 40px/33px 'bio-sans';
    letter-spacing: 0;
    color: #02000A;
    opacity: 1;
    width: 477px;
    height: 82px;
    background: linear-gradient(90deg, #77EDDC 0%, #9FFEBA 52%, #DEFE93 100%);
    border-radius: 8px;
}

.digitkeyboard:active {
    box-shadow: inset 0px 0px 30px #33FFC8;
    border: 2px solid #33FFC8;
}

.bottomButtons {
    bottom: 175px;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.todayConf {
    top: 973px;
    left: 1432px;
    height: 59px;
    font: 48px/88px 'bio-sans', sans-serif;
    letter-spacing: 0;
    color: #726FA880;
    text-align: right;
    opacity: 0.5;

}

.activeConferenceNameDiv {
    align-self: center;
    position: relative;
    width: 1355px;
    height: 214px;
    opacity: 1;
    text-align: right;
    display: flex;
    flex-direction: column;
}

.nameTodaysConf {
    font: 64px/66px 'bio-sans', sans-serif;
    color: #726FA8;
    text-align: right;
    opacity: 0.5;
    line-height: 1;
}