@import url("https://use.typekit.net/chq2xpl.css");

.component {
    top: 220px;
    left: 85px;
    /* width: 640px; */
    /* height: auto;
    background: transparent linear-gradient(180deg, #2E2748 0%, #221A3E 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 20px 60px #060020E6;
    border: 2px solid #5E547C; */
    font: normal normal 600 48px/50px "Bio Sans";
    color: #FFFFFF;
    position: absolute;
    z-index: 11;
    display: flex;
    flex-direction: column;
    border-radius: 20px;
    user-select: none;
}

.title{
    display: flex;
    flex-direction: row;
    margin-top: 50px;
    margin-left: 120px;
    /* width: 525px; */
    /* margin-right: 40px; */
    position: relative;

}

.iconCircle{
    width: 120px !important;
    height: 120px;
    background: transparent linear-gradient(180deg, #EEEEEE 0%, #C1C1C1 100%) 0% 0% no-repeat padding-box;
    border-radius: 50%;
    margin: 12px;
    position: relative;
    text-align: center;
    margin-left: 52px;
    margin-top: 22px;
}

.iconBackground {
    width: 140px;
    height: 140px;
    background: #150C2E 0% 0% no-repeat padding-box;
    border: 2px solid #FFE44D;
    position: absolute;
    top: 10px;
    left: 40px;
    border-radius: 50%;

}
.nameDetails{
    margin-left: 47px;
    width: 370px;
    /* margin-right: 40px; */
    align-self: center;
}

.role {
    font: normal normal bold 22px/27px "Bio Sans";
    text-transform: uppercase;
}

.icon{
    align-self: center;
    text-align: center;
    font-size: 68px;
    color: #0D0032;
    text-shadow: 0px 2px 8px #00000033;
    margin-top: 25px;
}

.details {
    margin-top: 30px;
    /* border-top: 1px solid #5E547C;
    border-bottom: 1px solid #5E547C; */
    display: flex;
    flex-direction: row;
    font: normal normal normal 26px/42px "Bio Sans";
    color: #CDCCD2;
    padding: 20px 30px 20px 30px;
    position: relative;
    margin-left: 100px;
    white-space: nowrap;
}
.function{
    width: 50%;
    border-right: 1px solid #5E547C;
    margin-right: 20px;
    font: normal normal 600 28px/42px "Bio Sans";
    user-select: none;
}

.iconLogout{
    margin-right: 20px;
}

.background{
    top: -100px;
    left: 0px;
    position: absolute;
    pointer-events: none;
}