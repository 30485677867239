.component {
    background: transparent linear-gradient(180deg, #2E2748 0%, #221A3E 100%) 0% 0% no-repeat padding-box;
    width: auto;
    height: auto;
    display: flex;
    flex-direction: column;
    position: fixed;
    padding: 7px;
    border-radius: 20px;
    bottom: 50px;
    left: 50%;
    transform: translate(-50%, 0);
}

.line {
    display: flex;
    flex-direction: row;
}