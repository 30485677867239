@import url("https://use.typekit.net/chq2xpl.css");


.status_button_selected{
    width: auto;
    height: 60px;
    background: #EEEEEE 0% 0% no-repeat padding-box;
    border-radius: 50px;
    color: #060020;
    font-size: 40px;
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 15px;
    margin-left: 20px;
    font: normal normal normal 40px/42px "Bio Sans";
    position: relative;
}

.status_button{
    width: auto;
    height: 60px;
    background:  #3F3857 0% 0% no-repeat padding-box;
    border-radius: 50px;
    color: #FFFFFF;
    font-size: 40px;
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 15px;
    margin-left: 20px;
    font: normal normal normal 40px/42px "Bio Sans";
    position: relative;
    cursor: pointer;

}
