.component{
    background-color: #2D2748;
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0px;
    top: 0px;
    z-index: 99;
    
}

.icon{
margin-right: 10px;
}