.component{
    
    padding: 7px;
}

.button{
    background: #362A55CC 0% 0% no-repeat padding-box;
    border-radius: 22px;
    height: 120px;
    
    border: none;
    border-style: none;
    margin: 0px;
    padding: 0px;
    transition: all 0.5s;
    color: white;
    font: normal normal 300 32px/39px "Bio Sans";
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
}

.button_selected {
    background: white 0% 0% no-repeat padding-box;
    border-radius: 22px;
    height: 120px;
    
    border: none;
    border-style: none;
    margin: 0px;
    padding: 0px;
    transition: all 0.5s;
    color: black;
    font: normal normal 300 32px/39px "Bio Sans";
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
}

.button:active{
    background: white;   
    color: black; 
}