@import url("https://use.typekit.net/chq2xpl.css");

.element{
    width: 1045px;
    height: auto;
    /* background: #231A3E 0% 0% no-repeat padding-box; */
    background: #231A3E80 0% 0% no-repeat padding-box;
    
    border-radius: 20px;
    /* margin-left: 15px; */
    padding: 50px;
    margin-top: 20px;
    font: normal normal 600 48px/54px "Bio Sans";
    color: #FFFFFF;
    border-style: solid;
    backdrop-filter: blur(31px);

}

.pastElement {
    width: 1045px;
    height: auto;
    backdrop-filter: blur(31px);
    border-radius: 20px;
    padding: 50px;
    margin-top: 20px;
    font: normal normal 600 48px/54px "Bio Sans";
    color: #FFFFFF80;
    background: #231A3E80 0% 0% no-repeat padding-box;
    /* opacity: 0.5; */
}

.upcomingConference {
    width: 1040px;
    height: auto;
    background: transparent linear-gradient(180deg, #2E2748 0%, #221A3E 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 20px #060020BC;
    border: 4px solid #33FFC8;
    border-radius: 20px; 
    margin-left: 15px;
    padding: 50px;
    margin-top: 10px;
    font: normal normal 600 48px/54px "Bio Sans";
    color: #FFFFFF;

}


.title_tags{
    height: 40px;
    justify-content: left;
    display: flex;
    flex-direction: row;
    font-size: 20px;
    margin-left: -10px;
    margin-bottom: 10px;
}

.info{
    display: flex;
    flex-direction: row;
    justify-content: space-between;

}

.details{
    padding: 20px 0px 0px 0px;
    display: flex;
    flex-direction: row;
    /* justify-content: center; */
    text-align: left;
    margin-top: 20px;
    border-top: 2px solid #FFFFFF33;
    
}

.line{
    margin-top: 20px;
    height: 2px;
    background-color: #FFFFFF33;
}

.half_detail{
    width: 35%;
    justify-content: left;
    display: flex;
    flex-direction: column;
    font: normal normal normal 28px/28px "Bio Sans";
    margin-right: 25px;
}

.icon {
    margin-right: 15px;
}

.iconArrow {
    margin-right: 20px;
    margin-top: 20px;
}

.detail_accent{
    margin-top: 10px;
    font: normal normal 600 48px/52px "Bio Sans";
}

.buttons {
    display: flex;
    flex-direction: row;
    margin-top: 50px;
}

.moreInfo {
    height: 45px;
    position: relative;
    display: flex;
    flex-direction: row;
    padding-top: 10px;
    margin-left: -16px;
}

.iconButton {
    font-size: 34px;
    position: absolute;
    top: 27px;
    right: 0px;
}