.main {
    top: 37px;
    height: 120px;
    width: auto; /* Define a width if necessary */
    background: #FF1F20 0% 0% no-repeat padding-box;
    box-shadow: 0px 20px 40px #0000008B;
    border: 4px solid #FF4918;
    border-radius: 20px;
    left: 50%;
    position: absolute;
    transform: translate(-50%, 0%);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
}

.textNotif {
    padding-left: 20px;
    font-size: 39px;
    color: #FFFFFF;
    text-align: left;
    font-weight: lighter !important; 
    font: lighter normal 300 "Bio Sans";
    margin-top: -10px;
}

.close {
    width: 30px;
    margin-top: -6px;
    padding-left: 40px;
    padding-right: 48px;
    font: normal 40px / 194px "Font Awesome 6 Pro";
    color: #FFFFFF;
    opacity: 1;
}