.hours {
    position: relative;
    display: flex;
    font: normal normal 600 49px/69px "Bio Sans";
    width: 100%;
    margin-top: 10px;
}


.time {
    position: relative;
    padding: 20px 25px 20px 25px;
    margin-top: 20px;
    margin-bottom: 20px;
    width: 153px;
    display: flex;
    background: #261E41 0% 0% no-repeat padding-box;
    border-right: 1px solid grey;
}

.minutes {
    font: normal normal 300 49px/69px "Bio Sans";
}




.selectedHour {
    width: 100%;
    height: 110px;
    background: transparent linear-gradient(90deg, #77EDDC 0%, #9FFEBA 52%, #DEFE93 100%) 0% 0% no-repeat padding-box;
    position: relative;
    pointer-events: none;
    margin-top: 30px;
    margin-bottom: 50px;
}

.selectedHourBigBox {
    width: 204px;
    top: 0px;
    left: 50%;
    /* width: 151px; */
    height: auto;
    position: absolute;
    transform: translate(-50%, 0);
    pointer-events: none;
}


.dayConferenceHour {
    top: 31.5px;
    left: 50%;
    width: 182px;
    height: 103px;
    background: transparent linear-gradient(90deg, #5E547C 0%, #5E547C66 100%) 0% 0% no-repeat padding-box;
    position: absolute;
}

.nextDay{
    font: normal normal normal 28px/42px "Bio Sans";
    color: #33FFC8;
    height: 42px;
    position: absolute;
    top:-40px;
}
.roomDetails{
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    position: absolute;
}

.roomName{
    left: 25px;
    position: relative;
    font: normal normal bold 40px/42px "Bio Sans";
    width: 320px;
}

.roomLocation{
    left: 25px;
    position: relative;
    font: normal normal normal 32px/40px "Bio Sans";
}

.movingHours{
    position: absolute;
    top: 0px;
    user-select: none;
}

.movingHoursForSelection{
    position: absolute;
    top: 0px;
    pointer-events: none;
}

.detailsLine{
    position: relative;
    height: 109px;
    background: #261E41 0% 0% no-repeat padding-box;
    /* opacity: 0.5; */
}

.conferencesDisplay{
    position: absolute;
    top: 30px;
    pointer-events: none;
    height: 270px;
    width: 100%;
}

.existingConference{
    position: absolute;
    background-color: #FF3B00;
    opacity: 0.4;
    height: 109px
}

.conferencesPerRoom{
    position: relative;
    height: 109px;
    width: 200%;
    margin-bottom: 50px;
}