.component{
    width: 100%;
    display: flex;
    flex-direction: column;
    font-size: 35px;
    /* padding-left: 20px; */
    height: 1000px;
    overflow: scroll;
    border-bottom: 1px solid #484060;
    /* padding: 20px; */
}

.component::-webkit-scrollbar {
    display: none;
}

.element{
    padding: 30px;
    height: 60px;
    border-bottom: 1px solid #484060;
    font: normal normal normal 40px/40px "Bio Sans";
    color: #CDCCD2;
    display: flex;
    flex-direction: row;
    position: relative;
    /* justify-content: space-between; */
}

.centerElement{
    position: absolute;
    top: 35px;
    left: 50%;
    transform: translate(-50%, 0%);
}

.rightElement{
    position: absolute;
    top: 35px;
    right: 30px;
}

.typeElement{
    position: absolute;
    top: 35px;
    left: 73%;
    transform: translate(-50%, 0%);
}
