.subtopicsListScrollBar {
    overflow-x: scroll;
    overflow-y: hidden;   

    &::-webkit-scrollbar {
        width: 20px;
        height: 3px;
    }

    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px transparent;
        border-radius: 10px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: black;
        border-radius: 10px;
    }
}

.subtopicElement {
    background-image: url("../../graphics/conference/topic-bgr.png");
    background-size: auto 100%;
    background-position-x: right;
    background-position-y: bottom;

    background-repeat: no-repeat;

    color: #C3C2CA;
    display: flex;
    flex-direction: column;
    flex: 0 0 auto;
    justify-content: center;

    cursor:pointer;
}

.title {
    margin-left: 20px;
    font: normal normal 600 40px/43px 'Bio Sans';
}

.subtitle {
    margin-left: 20px;
    font: normal normal 300 28px/32px 'Bio Sans';
}    



.fileElement {
    

    
    color: #C3C2CA;
    display: flex;
    flex-direction: column;
    flex: 0 0 auto;
    justify-content: center;
    align-items: center;

    cursor:pointer;
}

.file_title {
    margin-left: 20px;
    font: normal normal 600 18px/20px 'Bio Sans';
}