@import url("https://use.typekit.net/chq2xpl.css");

.component {
    width: 370px;
    height: 82px;
    background: #33FFC8 0% 0% no-repeat padding-box;
    border-radius: 16px;
    margin-left: 20px;
    font: normal normal 600 40px/80px "Bio Sans";
    color: #02000A;
    /* text-transform: uppercase; */
    /* align-items: center; */
    text-align: center;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
}