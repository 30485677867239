.duration {
    position: relative;
    margin-top: 20px;
    text-align: center;
    font: normal normal normal 34px/39px "Bio Sans";
    color: #CDCCD2;
}

.durationInput {
    font: normal normal 300 160px/120px "Bio Sans";
    color: #FFFFFF;
}



.hoursMin {
    margin-top: 20px;
    display: flex;
    justify-content: center;
}

.hoursMinLeft {
    margin-right: 150px;
}