@import url("https://use.typekit.net/chq2xpl.css");


.goBack {
    margin-left: 40px;
    margin-right: 20px;
    margin-top: 56px;
    font-size: 48px;
    position: relative;

}

.goBackText {
    font: normal normal 600 40px/160px "Bio Sans";
    position: relative;
}

.notifications {
    width: 90px;
    height: 90px;
    margin-top: 35px;
    margin-left: 20px;
    text-align: center;
    font-size: 48px;
    color: #EEEEEE;
    font: normal normal 300 48px/90px "Bio Sans";
    position: relative;

}

.titleCenterText{
    text-align: center;
    text-align: center;
    font: normal normal 600 68px/120px "Bio Sans";
    color: #FFFFFF;
    /* left: 50%; */
    /* transform: translate(-50%, 0); */
}

.editName {
    display: flex;
    flex-direction: row;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    margin-top: 15px;
}

.editIconClicked {
    font-size: 48px;
    margin-left: 40px;
    margin-top: 35px;
    position: relative;
    /* position: absolute; */
    /* left: 1290px; */
}

.editBackground {
    position: absolute;
    top: -9px;
    left:-60px;
    width: 1526.5px;
    height: 124px;
    background: #060020 0% 0% no-repeat padding-box;
    border: 12px solid #34FFC833;
    border-radius: 41px;
}

.switchDiv{
    font: normal normal 600 40px/50px "Bio Sans";
    color: #FFFFFF;
    display: flex;
    flex-direction: row;
    padding-top: 52px;
    margin-left: 3111px;
    position: relative;
}

.backgroundImg {
    width: 100%;
    position: absolute;
}