@import url("https://use.typekit.net/chq2xpl.css");

.element{
    width: 1045px;
    margin-top: 20px;
    background: #231A3E80 0% 0% no-repeat padding-box;
    border-radius: 24px;
    backdrop-filter: blur(31px);
    padding: 50px;
    font: normal normal 600 48px/54px "Bio Sans";
}
/* 
.subtopic{
    height: 40px;
    font: normal normal bold 28px/40px "Bio Sans";
    margin: 10px 0 10px 0;
}

.subtopicsTitle{
    font: normal normal normal 28px/42px "Bio Sans";
    color: #CDCCD2;
    margin-top: 28px;
}

.bookmark{
    margin-right: 20px;
} */

.headerTopic {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

}

.scrollTopicName::-webkit-scrollbar {
    display: none;
}

.index {
    margin-top: -30px;
    margin-bottom: 10px;
    font: normal normal 600 58px/80px "Bio Sans";
    color: #FFFFFF;
}

.details {
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    font: normal normal 600 48px/54px "Bio Sans";
    color: #CDCCD2;
}

.notExtended{
    position: relative;
    display: flex;
    flex-direction: column;
    font: normal normal 600 60px/71px "Bio Sans";
    color: #FFFFFF;
}

.icon{
    font-size: 28px;
    margin-right: 20px;
    margin-top: 15px;
}

.iconPlus{
    font-size: 32px;
    margin-right: 20px;
    top: -15px;
}

.moderatorInfo{
    margin-left: 50px;
    display: flex;
    flex-direction: row;
}

.extended{
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    border-top: 1px solid #5E547C;
    /* border-bottom: 1px solid #5E547C; */
    padding-top: 20px;
    padding-bottom: 20px;
    font: normal normal 600 28px/42px "Bio Sans";
    color: #CDCCD2;
    position: relative;
    /* font-family: "Bio Sans";
    font-weight: 600;
    font-style: normal; */
}

.subtopic{
    font: normal normal normal 48px/54px "Bio Sans";
    color: #FFFFFF;
    /* margin-left: 40px; */
    margin-top: 30px;
    margin-bottom: 20px;
    position: relative;
}

.moderator {
    padding-top: 20px;
    margin-top: 20px;
    border-top: 1px solid #5E547C;
}


.participantCard{
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: row;
}

.participantDetails {
    position: relative;
    display: flex;
    flex-direction: column;
    font: normal normal bold 22px/27px "Bio Sans";
    color: #EEEEEE;
    margin-top: 30px;
    margin-left: 25px;
}

.participantName {
    font: normal normal 600 48px/54px "Bio Sans";
    color: #FFFFFF;
}

.addSubtopic{
    font: normal normal 600 28px/54px "Bio Sans";
    color: #FFFFFF;
    position: absolute;
    top: 10px;
    right: 5px;
}

.iconOpenFiles {
    position: absolute;
    top: 70px;
    right: 0px;
}

.iconButton {
    font-size: 32px;
    position: absolute;
    top: 27px;
    right: 0px;
}

.iconButtonSubtopics {
    font-size: 28px;
    position: absolute;
    top: 10px;
    right: 0px;
}

.iconOpenFilesSubtopic{
    position: absolute;
    top: 10px;
    right: 0px;
    font-size: 28px;
}

.iconSubtopic{
    position: relative;
    top: 2px;
    margin-right: 20px;
    font-size: 28px;
}

.subtopicHeader {
    border-bottom: 1px solid #5E547C;
    padding-bottom: 20px;
}

.subtopicName{
    max-width: 940px;
    overflow: scroll;
}

.subtopicName::-webkit-scrollbar{
    display: none;
}
