.addedParticipantsList{
    position: absolute;
    top: 150px;
    left: 51px;
    width: 26%;
    max-height: calc(100% - 150px);
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
}

.addedParticipantsList::-webkit-scrollbar {
    display: none;
}

.positionCard{
    position: relative;
    height: 170px;
    width: 100%;
    background: #050020 0% 0% no-repeat padding-box;
    border-radius: 21px;
    margin-bottom: 21px;
    flex: 0 0 auto;
}


.numberedCircle {
    position: relative;
    margin-top: 20px;
    margin-left: 33px; 
    width: 121px;
    height: 121px;
    background: #6D6884 0% 0% no-repeat padding-box;
    border: 6px solid #060020;
    /* opacity: 0.45; */
    text-align: center;
    font: normal normal bold 72px/110px "Bio Sans";
    color: #1D1536;
    border-radius: 50%;
}

.assignedParticipant_offline{
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    position: absolute;
    top: 0px;
    left: 0px;
    display: flex;
    flex-direction: row;
    background: #55516B 0% 0% no-repeat padding-box;
    border-radius: 20px;
    opacity: 1;
    user-select: none;
}

.assignedParticipant_online{
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    position: absolute;
    top: 0px;
    left: 0px;
    display: flex;
    flex-direction: row;
    background: #00000080 0% 0% no-repeat padding-box;
    border-radius: 20px;
    opacity: 1;
    cursor: pointer;
}

.assignedParticipant_online{
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    position: absolute;
    top: 0px;
    left: 0px;
    display: flex;
    flex-direction: row;
    background: #00000080 0% 0% no-repeat padding-box;
    border-radius: 20px;
    opacity: 1;
    cursor: pointer;
}

.assignedParticipant_selected{
    width: 100%;
    height: 100%;
    position: absolute;
    box-sizing: border-box;
    top: 0px;
    left: 0px;
    display: flex;
    flex-direction: row;
    background: #00000080 0% 0% no-repeat padding-box;
    border-radius: 20px;
    opacity: 1;
    cursor: pointer;
    border: #33FFC8 solid 2px;
}

.assignedDeskNumber{
    position: absolute;
    top: 0px;
    left: 0px;
    width: 62px;
    height: 62px;
    background: #33FFC8 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 40px #000000E5;
    border: 6px solid #1D1536;
    text-align: center;
    font: normal normal bold 33px/60px "Bio Sans";
    color: #1D1536;
    border-radius: 50%;
}

.participantDetails {
    position: relative;
    display: flex;
    flex-direction: column;
    font: normal normal bold 22px/27px "Bio Sans";
    color: #EEEEEE;
    margin-top: 30px;
    margin-left: 25px;
}

.participantName {
    font: normal normal 600 48px/54px "Bio Sans";
    color: #FFFFFF;
}

.participantListShadow{
    position: absolute;
    bottom: calc(10% - 2px);
    left: 2px;
    width: 100%;
    height: 161px;
    background: transparent linear-gradient(180deg, #2D274800 0%, #2D2748 100%) 0% 0% no-repeat padding-box;
    
}