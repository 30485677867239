.component {
    position: fixed;
    left: 260px;
    top: -70px;
    width: 739px;
    height: 255px;
    background: #EEEEEE;
    box-shadow: 0 0 60px #000;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    transform: matrix(1, 0, 0, 1, 1090, 1000);
    border-radius: 20px;
    /* align-content: center; */
    justify-content: center;

}

.divColumn {
    display: flex;
    flex-direction: column;
    border-radius: 20px;
    position: relative;

}

.boldText {
    font: bold 40px/42px 'bio-sans', sans-serif;
    letter-spacing: 0;
    text-align: left;
    color: #050020;
    position: relative;
}

.question {
    position: relative;
    font: 28px/42px 'bio-sans', sans-serif;
    text-align: left;
    color: #110A2C;
    margin-top: 0px;
    text-wrap: wrap;
}

.trash {
    width: 70px;
    height: 100px;
    position: relative;
    top: -52px;
    /* left: 35px; */
}