@import url("https://use.typekit.net/chq2xpl.css");

.component {
    width: 160px;
    height: auto;
    background: transparent linear-gradient(180deg, #EEEEEE 0%, #C1C1C1 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 10px 80px #000000DD;
    display: flex;
    flex-direction: column;
    border-radius: 12px;
    position: absolute;
    transform: matrix(1, 0, 0, 1, 1090, 1000);
    /* z-index: -1; */
    font-size: 60px;
    
}

.edit{
    width: 140px;
    height: 113px;
    border-radius: 8px;
    margin:  10px 10px 0px 10px;
    color: #060020;
    text-align: center;
    padding-bottom: 15px;
    /* padding: 28px 0px 30px 0px; */
}

.remove{
    width: 140px;
    height: 113px;
    background: #FF1F20 0% 0% no-repeat padding-box;
    border-radius: 8px;
    margin:  10px;
    color: white;
    text-align: center;
    padding-bottom: 15px;
    /* padding: 28px 0px 30px 0px; */
}

.buttonText{
    font: normal normal bold 22px/27px "Bio Sans"; 
    /* margin-top: 5px; */
}
