.select_lists{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
}

.select_options{
    width: 100%;
    display: flex;
    flex-direction: row;
    position: relative;
    margin-top: 10px;
}

.search {
    width: 47%;
    height: 85%;
    display: flex;
    flex-direction: column;
    padding: 20px;
    font-size: 30px;
}

.tableHeader{
    height: 40px;
    padding: 20px 30px 20px 30px;
    border-bottom: 4px solid #484060;
    border-top: 4px solid #484060;
    font: normal normal 600 32px/36px "Bio Sans";
    color: #9B99A5;
    text-transform: uppercase;
    margin-top: 370px;
    display: flex;
    flex-direction: row;
    width: 1650px;
    /* justify-content: space-between; */
    position: absolute;

}

.centerElement{
    position: absolute;
    /* top: 35px; */
    left: 50%;
    transform: translate(-50%, 0%);
}

.rightElement{
    position: absolute;
    /* top: 35px; */
    right: 30px;
}

.icon{
    position: absolute;
    font: normal normal 300 48px/58px "Bio Sans";
    color: #E4E4E4;
    margin-top: 55px;
    margin-left: 40px;
}

.addIcon{
    margin-top: 20px;
    margin-right: 20px;

}

.selectionBody{
    display: flex;
    flex-direction: row;
}

.selectRole{
    display: flex;
    flex-direction: row;
    /* margin-top: 25px; */
}

.tablePart{
    display: flex;
    flex-direction: column;
}

.circle {
    position: absolute;
    margin-left: 2200px;
    margin-top: 1500px;
    width: 120px;
    height: 120px;
    background: #34FFC8 0% 0% no-repeat padding-box;
    border: 16px solid #060120;
    border-radius: 1000px;
    color: #060120;
    text-align: center;
    font: normal normal normal 100px/100px "Bio Sans";
}

.tag{
    height: 57px;
    border-radius: 50px;
    background-color: #FFFFFF1A;
    color: white;
    position: relative;
    text-align: center;
    font: normal normal normal 36px/36px "Bio Sans";
    padding: 20px 40px 0px 40px;
    margin-right: 20px;
}

.selectedTag{
    height: 57px;
    border-radius: 50px;
    background-color: white;
    color: #060020;
    position: relative;
    text-align: center;
    font: normal normal normal 36px/36px "Bio Sans";
    padding: 20px 40px 0px 40px;
    margin-right: 20px;

}

.participantsList{
    position: relative;
    width: 100%;
    max-height: 1450px;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
}

.participantsList::-webkit-scrollbar {
    display: none;
}

.participantCard{
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: row;
}

.participantDetails {
    position: relative;
    display: flex;
    flex-direction: column;
    font: normal normal normal 22px/27px "Bio Sans";
    color: #EEEEEE;
    margin-top: 40px;
    margin-left: 25px;
}

.participantName {
    font: normal normal 600 48px/54px "Bio Sans";
    color: #FFFFFF;
}

.participantListShadow{
    position: absolute;
    bottom: calc(10% - 2px);
    left: 50%;
    width: 50%;
    height: 161px;
    background: transparent linear-gradient(180deg, #2D274800 0%, #2D2748 100%) 0% 0% no-repeat padding-box;
    
}
.participantListShadow{
    position: absolute;
    bottom: calc(10%);
    left: 50%;
    width: 50%;
    height: 161px;
    background: transparent linear-gradient(180deg, #2D274800 0%, #2D2748 100%) 0% 0% no-repeat padding-box;
}

.participantListShadowUp{
    position: absolute;
    top: calc(15% - 4px);
    left: 50%;
    width: 50%;
    height: 80px;
    background: transparent linear-gradient(180deg, #2D2748 0%, #2D274800 100%) 0% 0% no-repeat padding-box;
}